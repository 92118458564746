<template>
    <form :action="route" method="GET">
        <div class="card mt-4 mb-2 px-0 py-0">
            <ul class="nav pt-4" style="border-bottom: 1px solid rgba(0, 40, 100, 0.12);">
                <li class="nav-item">
                    <a :class="`nav-link campaign-filter pb-4 pl-1 ${initCampaign == 'kafalah' ? 'active' : ''}`" href="#" @click="goTo('kafalah')">Kafalah</a>
                </li>
                <li class="nav-item">
                    <a :class="`nav-link campaign-filter pb-4 pl-1 ${currentRoute == 'recurring-groups' ? 'active' : ''}`" :href="routeRecurringGroups">Kafalah Group</a>
                </li>
                <li class="nav-item">
                    <a :class="`nav-link campaign-filter pb-4 ${initCampaign == 'autodebit' ? 'active' : ''}`" href="#" @click="goTo('autodebit')">Autodebit</a>
                </li>
                <!-- <li class="nav-item">
                    <a :class="`nav-link campaign-filter pb-4 ${initCampaign == 'all' ? 'active' : ''}`" href="#" @click="goTo('all')">All</a>
                </li> -->
                <li class="nav-item">
                    <a :class="`nav-link campaign-filter pb-4 ${initCampaign == 'inactive' ? 'active' : ''}`" href="#" @click="goTo('inactive')">Archived</a>
                </li>
            </ul>
            <input type="hidden" name="campaign" :value="initCampaign">
            <div v-if="currentRoute == 'recurrings'">
                <div class="row my-0 px-4">
                    <div class="col-2 pr-0">
                        <div class="form-group mt-4 mb-0">
                            <p class="form-label text-gray">Recurring ID</p>
                            <div class="input-group">
                                <span class="input-group-prepend" id="basic-addon1">
                                    <span class="input-group-text">#</span>
                                </span>
                                <input type="text" class="form-control" name="id" id="id" placeholder="Recurring id" v-model="id">
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="form-group mt-4 mb-0 px-4">
                            <p class="form-label text-gray">Donors</p>
                            <select name="donor_id" id="donor_id" class="donor_id">
                                <option value="" selected>Please enter the donor name, no. identification card or email...</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row my-0 px-4 py-4">
                    <div class="col">
                        <div class="form-group mb-4">
                            <div class="form-label text-gray">Bank</div>
                            <select name="bank_id" id="bank_id" v-model="bankId">
                                <option value="" selected>Please pick one...</option>
                                <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col" v-if="initCampaign != 'autodebit'">
                        <div class="form-group mb-4">
                            <div class="form-label text-gray">Status</div>
                            <select name="status" id="status" v-model="status">
                                <option value="">Please pick one...</option>
                                <option value="notStart">Not Start</option>
                                <option value="running">Running</option>
                                <option value="expired">Expired</option>
                            </select>
                        </div>
                    </div>
                    <div class="col" v-if="initCampaign != 'autodebit'">
                        <div class="form-group mb-4">
                            <div class="form-label text-gray">Campaign Package</div>
                            <select name="campaign_package_id" id="campaign_package_id" v-model="campaignPackageId">
                                <option value="" selected>Please pick one...</option>
                                <option v-for="campaignPackage in campaignPackages" :value="campaignPackage.id">{{ campaignPackage.name[lang] }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group mb-4">
                            <div class="form-label text-gray">Date</div>
                            <datepicker
                                id="datepicker"
                                class="mt-2 mb-0"
                                :start="start"
                                :end="end"
                                :use-vuex="false"
                                :allow-empty="true"
                            >
                            </datepicker>
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-2 px-4 d-flex justify-content-end">
                    <a v-if="getDonorId()" :href="`/admin/recurrings?campaign=${initCampaign}`" class="btn btn-link">Clear</a>
                    <button type="submit" class="btn btn-secondary"><i class="fe fe-filter mr-2"></i>Filter</button>
                </div>
            </div>
        </div>
    </form>
</template>

<style>
    .campaign-filter {
        border-bottom: 3px solid #fff;
    }
    .campaign-filter.active {
        border-bottom: 3px solid #35a1e8;
    }
</style>

<script>
    export default {
        data() {
            return {
                id: this.initId,
                bankId: this.initBankId,
                campaignPackageId: this.initCampaignPackageId,
                status: this.initStatus
            };
        },
        props: {
            currentRoute: {},
            route: {},
            routeRecurringGroups: {},
            banks: {},
            initId: {
                default: ''
            },
            initCampaign: {},
            initBankId: {},
            initCampaignPackageId: {
                default: ''
            },
            start: {},
            end: {},
            campaignPackages: {},
            lang: {},
            initDonor: {
                default: null
            },
            initStatus: {
                default: null
            },
        },
        computed: {
        },
        methods: {
            goTo(campaign) {
                this.campaignPackageId = campaign == 'autodebit' ? '' : this.initCampaignPackageId;
                
                window.location.href = `${this.route}?id=${this.id}&donor_id=${_.get(this.initDonor, 'id', '')}&campaign=${campaign}&bank_id=${this.bankId}&campaign_package_id=${this.campaignPackageId}`;
            },
            clearOptions() {
                this.selectizeDonor[0].selectize.close();
                this.selectizeDonor[0].selectize.clearOptions();
            },
            getDonorText(donor) {
                var nric = donor.nric ? '' + donor.nric + ' - ' : '';
                var fullname = donor.fullname ? donor.fullname + ' ' : '';
                var email = donor.email ? '(' + donor.email + ')' : '';
                
                return nric + fullname + email;
            },
            getDonorId() {
                return _.get(this.initDonor, 'id');
            }
        },
        mounted() {
            var that = this;

            // SELECT2
            var $search_select = $('#donor_id').select2({
                ajax: {
                    url: '/admin/donors',
                    dataType: 'json',
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page || 1,
                            override: !params.term ? 'empty' : ''
                        }
                    },
                    processResults: function (data, params) {
                        var items = data.items.data.map(function (donor) {
                            return {
                                id: donor.id,
                                text: that.getDonorText(donor)
                            }
                        });

                        params.page = params.page || 1;

                        return {
                            results: items,
                            pagination: {
                                more: (params.page * 30) < data.total_count
                            }
                        };
                    },
                    cache: true
                },
                width: '100%'
            })

            if (this.getDonorId()) {
                $search_select.append(new Option(this.getDonorText(this.initDonor), this.getDonorId(), true, true)).trigger("change");
            }

            // SELECTIZE
            // this.selectizeDonor = $(`#donor_id`).selectize({
            //     valueField: 'id',
            //     labelField: 'fullname',
            //     searchField: ['nric', 'fullname', 'email'],
            //     options: [],
            //     create: false,
            //     plugins: ['remove_button'],
            //     render: {
            //         option: function(donor, escape) {
            //             var nric = donor.nric ? '' + escape(donor.nric) + ' - ' : '';
            //             var fullname = donor.fullname ? escape(donor.fullname) + ' ' : '';
            //             var email = donor.email ? '(' + escape(donor.email) + ')' : '';
            //             return '<div class="option">' +
            //                 nric +
            //                 fullname +
            //                 email +
            //                 '</div>';
            //         },
            //         item: function(donor, escape) {
            //             var nric = donor.nric ? '' + escape(donor.nric) + ' - ' : '';
            //             var fullname = donor.fullname ? escape(donor.fullname) + ' ' : '';
            //             var email = donor.email ? '(' + escape(donor.email) + ')' : '';
            //             return '<div class="option">' +
            //                 nric +
            //                 fullname +
            //                 email +
            //                 '</div>';
            //         }
            //     },
            //     load: function(query, callback) {
            //         var override = '';
            //         if(!query.length) override = '&override=empty';
            //         $.ajax({
            //             url: '/admin/donors?q=' + query + override,
            //             type: 'GET',
            //             error: function() {
            //                 callback();
            //             },
            //             success: function(res) {
            //                 if (!res.length) {
            //                     that.clearOptions();
            //                 } else {
            //                     callback(res);
            //                 }
            //             }
            //         });
            //     },
            //     onClear: function (value) {
            //         that.clearOptions();
            //     },
            // });

            // prevent submitting form when clicking enter before options loaded
            // $(`#donor_id-selectized`).keydown(function (e) {
            //     if (e.keyCode == 13) {
            //         e.preventDefault();
            //         return false;
            //     }
            // });

            // if (_.get(this.initDonor, 'id')) {
            //     this.selectizeDonor[0].selectize.addOption(this.initDonor);
            //     this.selectizeDonor[0].selectize.setValue(this.initDonor.id, false);
            // }
        }
    }
</script>