<template>
    <div class="input-group">
        <div class="input-group-prepend">
            <button type="button" class="btn btn-outline-secondary" @click="minus"><i class="fe fe-minus"></i></button>    
        </div>
        <input class="form-control text-center" type="text" v-model="counter" :name="name" @blur="counter = Math.abs(counter)">   
        <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary" @click="add"><i class="fe fe-plus"></i></button>  
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                counter: this.initCounter || 0
            };
        },
        props: ['name', 'initCounter'],
        methods: {
            add(event) {
                event.preventDefault
                this.counter++;
                this.$emit('change-counter', this.counter);
            },
            minus(event) {
                this.counter--;
                if (this.counter < 1) {
                    this.counter = 0
                }
                this.$emit('change-counter', this.counter);
            }
        },
        watch: {
            counter: function (newCounter, oldCounter) {
                if (isNaN(newCounter)) {
                    return
                }

                if (newCounter < 0) {
                    newCounter = Math.abs(newCounter)
                }

                this.$emit('change-counter', newCounter);
            }
        },
        mounted() {
        }
    }
</script>
