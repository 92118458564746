<template>
    <div>
        <form ref="form" :action="routeStore" method="POST">
            <input type="hidden" name="_token" :value="csrf" />
            <div class="card mt-4">

                <donor-search
                    :init-donor="donor"
                    @changeDonor="handleChangeDonor($event)"
                >
                </donor-search>

                <!-- list_bank_card -->
                <div v-if="donor.id && donor.active_recurrings" class="card-body" id="list_bank_card">
                    <div class="row">
                        <div class="col-3">
                            <h4>List of Subscriptions</h4>
                        </div>
                        <div class="col-9">
                            <div v-for="active_recurring in donor.active_recurrings" class="row mt-0 mb-5">
                                <div class='col-9 d-flex align-items-top'>
                                    <div>
                                        <div class="d-flex justify-content-start align-items-center">
                                            <span class="font-weight-bold text-lg mr-2">#{{ active_recurring.id }} {{ (active_recurring.campaign ? active_recurring.campaign.name[lang] : '') }}</span>
                                            <span v-if="active_recurring.recurring_group_id" class="badge badge-info mb-0 text-white mr-2">Group #{{ active_recurring.recurring_group_id }}</span>
                                            <span v-if="active_recurring.package" class="badge badge-secondary mr-2">{{ active_recurring.package.name[lang] }}</span><br>
                                            <span v-if="active_recurring.amount" class="text-muted">RM{{ Number(active_recurring.amount).toFixed(0) }}/month</span>
                                        </div>
                                        <div v-if="active_recurring.bank || active_recurring.no_account">
                                            <span v-if="active_recurring.bank" class="text-lg">{{ active_recurring.bank.name }}</span>
                                            <span v-if="active_recurring.no_account" class="text-muted">({{ active_recurring.no_account }})</span>
                                        </div>
                                        <p v-if="active_recurring.start_date && active_recurring.end_date" class="mb-0 text-muted">
                                            <span class="text-xs font-weight-bold" style="color:cornflowerblue">{{ getFormattedDate(active_recurring.start_date) }}</span>
                                            <span class="text-xs text-muted font-italic"> till </span>
                                            <span class="text-xs font-weight-bold" style="color:cornflowerblue">{{ getFormattedDate(active_recurring.end_date) }}</span>
                                        </p>
                                    </div>
                                </div>
                                <div :class="`col-3 ${ active_recurring.no_account ? 'pt-2' : '' }`">
                                    <button @click="findRecurringBills(active_recurring)" type="button" :class="'float-right btn ' + (recurring && active_recurring.id == recurring.id ? 'btn-primary' : 'btn-secondary')" id="donation" :value="active_recurring.id">Select</button>
                                </div>
                            </div>
                            <div v-if="!donor.active_recurrings.length">
                                <span class="text-secondary">No Subscriptions</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- create_bill -->
                <div v-if="donor.id && recurring && recurring.id" class="card-body" id="create_bill">
                    <div class="row">
                        <div class="col-3">
                            <h4>Donation Details</h4>
                        </div>
                        <div class="col-9">
                            <div v-if="recurring.bank_id"  class="form-group">
                                <div class="form-label">Bank Name</div>
                                <input class="form-control" style="width: 50%;" type="text" :value="recurring.bank ? recurring.bank.name : ''" disabled>
                            </div>

                            <div v-if="recurring.no_account" class="form-group">
                                <div class="form-label">No Bank Account</div>
                                <input class="form-control" style="width: 50%;" type="text" :value="recurring.no_account" disabled>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Branch </div>
                                <select class="form-control w-50" name="branch" style="width: 40%;" v-model="donor.branch_id">
                                    <option>--- Choose one ---</option>
                                    <option v-for="branch in branches" :value="branch.id">{{ branch.name }}</option>
                                </select>
                                <input class="form-control" name="campaign" type="text" :value="recurring.campaign_id" hidden>
                                <input class="form-control" name="recurring_id" type="text" :value="recurring.id" hidden>
                                <input class="form-control" name="package" type="text" :value="recurring.package_id" hidden>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Payment Status <span class="text-red">*</span></label>
                                <div class="selectgroup w-50">
                                    <label class="selectgroup-item">
                                        <input type="radio" class="selectgroup-input" name="status" value="0">
                                        <span class="selectgroup-button">In Process</span>
                                    </label>
                                    <label class="selectgroup-item">
                                        <input type="radio" class="selectgroup-input" name="status" value="1" checked>
                                        <span class="selectgroup-button">Paid</span>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Method</div>
                                <select class="custom-select w-50" name="method" style="width: 40%;" v-model="recurring.method_id" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="method in methods" :value="method.id">{{ method.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Sector</div>
                                <select class="custom-select w-50" name="sector" style="width: 40%;" v-model="recurring.sector_id" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="sector in sectors" :value="sector.id">{{ sector.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Medium</div>
                                <select class="custom-select w-50" name="medium" style="width: 40%;" v-model="recurring.medium_id" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="medium in mediums" :value="medium.id">{{ medium.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Donation Amount (RM) <span class="text-red">*</span></div>
                                <input type="text" class="form-control w-50" name="amount" id="amount" :value="recurring.amount" hidden="true">
                                <input type="text" class="form-control w-50" name="amount_quantity" id="amount_quantity" :value="recurring.amount*quantity" disabled="">
                            </div>

                            <template v-if="recurring.start_date">
                                <div class="form-group">
                                    <div class="form-label">Payment by Month</div>
                                    <div class="row">
                                        <div class="row m-3" style="width:45%">
                                            <template v-for="(recurringPeriod, index) in recurringPeriods.slice(0,6)">
                                                <div class="col-5 p-2">
                                                    <input type="checkbox" v-model="checkbox[index]" :disabled="recurringBillCheckboxs[index] > 0">
                                                    <label> {{ recurringMonths[index] }}</label>
                                                </div>
                                                <div class="col-7 pt-2">
                                                    <template v-for="recurringBill in recurringBills">
                                                        <input v-if="getPeriodDate(recurringBill.deduction_date) >= recurringPeriod.start && getPeriodDate(recurringBill.deduction_date) <= recurringPeriod.end" class="form-control" type="text" :value="getFormattedDate(recurringBill.deduction_date)" disabled>  
                                                    </template>
                                                    <input v-if="recurringBillCheckboxs[index] < 1" type="date" class="form-control" name="deduction_dates[]" :min="getRangeDate(recurringPeriod.start)" :max="getRangeDate(recurringPeriod.end)" :disabled="!checkbox[index]">
                                                </div>
                                            </template>
                                        </div>
                                        <div class="row m-3" style="width:45%">
                                            <template v-for="(recurringPeriod, index) in recurringPeriods.slice(6,12)">
                                                <div class="col-5 p-2">
                                                    <input type="checkbox" v-model="checkbox[index+6]" :disabled="recurringBillCheckboxs[index+6] > 0">
                                                    <label> {{ recurringMonths[index+6] }}</label>
                                                </div>
                                                <div class="col-7 pt-2">
                                                    <template v-for="recurringBill in recurringBills">
                                                        <input v-if="getPeriodDate(recurringBill.deduction_date) >= recurringPeriod.start && getPeriodDate(recurringBill.deduction_date) <= recurringPeriod.end" class="form-control" type="text" :value="getFormattedDate(recurringBill.deduction_date)" disabled>  
                                                    </template>
                                                    <input v-if="recurringBillCheckboxs[index+6] < 1" type="date" class="form-control" name="deduction_dates[]" :min="getRangeDate(recurringPeriod.start)" :max="getRangeDate(recurringPeriod.end)" :disabled="!checkbox[index+6]">
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="form-group">
                                    <div class="form-label">No. of month payment <span class="text-red">*</span></div>
                                    <counter
                                        class="w-25"
                                        @change-counter="handleChangeCounter($event)"
                                        name="quantity"
                                        :init-counter="quantity"
                                    >
                                    </counter>
                                </div>

                                <div class="form-group">
                                    <div class="form-label">Transaction Date <span class="text-red">*</span></div>
                                    <input type="date" class="form-control w-50" id="deduction_date" :value="old.deduction_date || getTodayDate()" name="deduction_date">
                                    <span v-if="errors['deduction_date'] !== undefined" class="text-sm text-red">{{ errors['deduction_date'][0] }}</span>
                                </div>
                            </template>

                            <div class="form-group">
                                <div class="form-label">Remark </div>
                                <textarea class="form-control" name="remark" id="remark" rows="3"></textarea>
                            </div>

                            <div class="form-group">
                                <input type="checkbox" id="send_email" name="send_email" value="true" v-model="sendEmail">
                                <span class="ml-1 font-italic">Send receipt to donor's email and kafalah admin (kafalah@amanpalestin.net)</span>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 offset-3 mt-6">
                            <button :disabled="isSubmitting" @click="submit()" type="button" class="btn btn-primary">Add Donation</button>
                        </div>
                    </div>
                </div>

            </div> <!-- end card -->
        </form>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                donor: this.initDonor || null,
                recurring: this.initRecurring || null,
                recurringBills: null,
                campaignId: '',
                packageId: '',
                sendEmail: false,
                isSubmitting: false,
                quantity: 1,
                recurringPeriods: [],
                recurringMonths: [],
                checkbox: [],
                recurringBillCheckboxs: [0,0,0,0,0,0,0,0,0,0,0,0],
            };
        },
        props: [
            'lang',
            'routeStore',
            'csrf',
            'old',
            'errors',
            'initDonor',
            'initRecurring',
            'branches',
            'methods',
            'sectors',
            'mediums',
        ],
        computed: {
        },
        methods: {
            async findRecurringBills(recurring) {
                if (recurring === undefined) {
                    return;
                }

                this.quantity = 1;
                try {
                    const response = await axios.get(`/admin/recurring-bills/${recurring.id}`);
                    this.recurringBills = response.data;
                } catch (e) {
                    console.log('error ', e);
                }

                this.recurring = recurring;

                for (var i = 0; i < 12; i++) {
                    Vue.set(this.recurringMonths, i, moment(recurring.start_date).tz("Asia/Kuala_Lumpur").add(i, 'M').format('DD MMM YYYY'));
                    
                    Vue.set(this.recurringPeriods, i, { 
                        start: moment(recurring.start_date).tz("Asia/Kuala_Lumpur").add(i, 'M').format('YYYYMMDD'),
                        end: moment(recurring.start_date).tz("Asia/Kuala_Lumpur").subtract(1, 'd').add(i+1, 'M').format('YYYYMMDD'),
                    });
                }

                this.recurringPeriods.forEach((recurringPeriod, index) => {
                    this.recurringBills.forEach((recurringBill, i) => {
                        if (moment(recurringBill.deduction_date).format('YYYYMMDD') >= recurringPeriod.start && moment(recurringBill.deduction_date).format('YYYYMMDD') < recurringPeriod.end) {
                            this.recurringBillCheckboxs[index]++;
                        }
                    });
                });
            },
            handleChangeDonor(donor) {
                this.donor = donor;
                this.reloadRecurring(donor);
            },
            scrollToBottom() {
                var scrollingElement = (document.scrollingElement || document.body);
                scrollingElement.scrollTop = scrollingElement.scrollHeight;
            },
            getTodayDate() {
                return moment.tz("Asia/Kuala_Lumpur").format('YYYY-MM-DD');
            },
            getRangeDate(date) {
                if (date.date) {
                    return moment(date.date).tz("Asia/Kuala_Lumpur").format('YYYY-MM-DD')
                } else {
                    return moment(date).tz("Asia/Kuala_Lumpur").format('YYYY-MM-DD');
                }
            },
            getPeriodDate(date) {
                if (date.date) {
                    return moment(date.date).tz("Asia/Kuala_Lumpur").format('YYYYMMDD')
                } else {
                    return moment(date).tz("Asia/Kuala_Lumpur").format('YYYYMMDD');
                }
            },
            getFormattedDate(date) {
                if (date.date) {
                    return moment(date.date).tz("Asia/Kuala_Lumpur").format('DD MMM YYYY')
                } else {
                    return moment(date).tz("Asia/Kuala_Lumpur").format('DD MMM YYYY');
                }
            },
            getMonthDate(date) {
                if (date.date) {
                    return moment(date.date).format('MMM')
                } else {
                    return moment(date).format('MMM');
                }
            },
            submit() {
                this.isSubmitting = true;
                this.$refs.form.submit();
            },
            handleChangeCounter(count) {
                this.quantity = count;
            },
            reloadRecurring(donor) {
                if (donor && this.recurring) {
                    this.recurring = donor.active_recurrings.find(recurring => recurring.id === this.recurring.id);
                    this.findRecurringBills(this.recurring);
                }
            }
        },
        mounted() {
        },
        watch: {
            recurring: function(newRecurring, oldRecurring) {
                // only scroll to bottom on first time clicking Make Donation
                if (!oldRecurring && newRecurring) {
                    this.$nextTick(function () {
                        this.scrollToBottom();
                    });
                }
            }
        }
    }
</script>