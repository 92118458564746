<template>
    <div>
        <div class="card mt-3 mb-0">
            <div class="card-header">
                <h3 class="card-title">Kafalah (Group)</h3>
                <input type="text" name="campaign_id" :value="campaignId" hidden>
            </div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-8">
                        <select class="form-control" style="width: 75%;" v-model="packageId" :disabled="mode == 'fillDonor'">
                            <option value="null" disabled>--- Choose Package ---</option>
                            <option v-for="campaignPackage in campaignPackages" :value="campaignPackage.id">{{ campaignPackage.name[lang] }} (RM {{ campaignPackage.amount }})</option>
                        </select>
                        <input type="hidden" name="package_id" :value="packageId" readonly>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto">
                        <div class="form-group" :hidden="mode == 'fillDonor'">
                            <div class="selectgroup">
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="number_of_donors" :value="2" v-model="numberOfDonors">
                                    <span class="selectgroup-button">2 Donor</span>
                                </label>
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="number_of_donors" :value="3" v-model="numberOfDonors">
                                    <span class="selectgroup-button">3 Donor</span>
                                </label>
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="number_of_donors" :value="4" v-model="numberOfDonors">
                                    <span class="selectgroup-button">4 Donor</span>
                                </label>
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="number_of_donors" :value="5" v-model="numberOfDonors">
                                    <span class="selectgroup-button">5 Donor</span>
                                </label>
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="number_of_donors" :value="6" v-model="numberOfDonors">
                                    <span class="selectgroup-button">6 Donor</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group" :hidden="mode == 'fillPackage'">
                            <div class="selectgroup">
                                <label class="selectgroup-item">
                                    <span class="selectgroup-button">{{ numberOfDonors }} Donor</span>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div>
                    <span class="btn btn-link float-right" v-on:click="resetNumberOfDonor" v-if="mode == 'fillDonor'">Reset all form</span>
                    <button class="btn btn-primary float-right" v-on:click="setNumberOfDonor" v-if="mode == 'fillPackage'" :disabled="packageId == null">Next</button>
                </div>
            </div>
        </div>

        <div id="accordion">
            <div v-for="(recurring, index) in recurrings" :key="index" class="card mt-3 mb-0">
                <div class="card-header mb-0">
                    <h5 class="mb-0 d-flex align-items-center">
                        <span class="btn btn-link pl-0" data-toggle="collapse" :data-target="'#collapse-'+index" aria-expanded="true" :aria-controls="'collapse-'+index">
                          Donor {{ index+1 }}
                        </span>
                        <span class="text-muted text-xs"><i class="fa fa-arrow-circle-left mr-2 text-primary" aria-hidden="true"></i>Click to expand/shrink</span>
                    </h5>
                </div>
                <div class="collapse mt-4" :id="'collapse-'+index">

                    <!-- check_donor_status_card -->
                    <div :class="'card-body ' + (recurring.donorMode == 'find' ? 'border-bottom' : '')" id="check_donor_status_card">
                        <div class="row">
                            <div class="col-3">
                                <h4>Donor</h4>
                            </div>
                            <div class="col-9">
                                <div class="form-group">
                                    <label class="form-label">
                                        <input type="radio" :name="'donor_modes['+index+']'" value="new" class="mr-2" v-model="recurring.donorMode">
                                        <span class="">New</span>
                                    </label>
                                    <label class="form-label">
                                        <input type="radio" :name="'donor_modes['+index+']'" value="find" class="mr-2" v-model="recurring.donorMode">
                                        <span class="">Existing</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <donor-search
                        v-if="recurring.donorMode == 'find'"
                        :uniqueKey="index + 1"
                        :init-donor="recurring.donor"
                        @changeDonor="handleChangeDonor($event, index)"
                    >
                    </donor-search>

                    <!-- form_donor_card -->
                    <div v-if="recurring.donorMode == 'new'" class="card-body" id="form_donor_card">
                        <div class="row">
                            <div class="col-3">
                                <h4>Donor Details</h4>
                            </div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <div class="form-label">Presalutation</div>
                                            <select class="form-control" name="presalutations[]">
                                                <option value="">--- Choose One ---</option>
                                                <option v-for="presalutation in presalutations" :value="presalutation.id">{{ presalutation.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <div class="form-label">Salutation</div>
                                            <select class="form-control" name="salutations[]">
                                                <option value="">--- Choose One ---</option>
                                                <option v-for="salutation in salutations" :value="salutation.id">{{ salutation.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <div class="form-label">Fullname <span class="text-red">*</span></div>
                                            <input type="text" class="form-control" style="text-transform: uppercase;" name="fullnames[]" value="" required>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-5">
                                        <div class="form-group">
                                            <div class="form-label">Username <span class="text-muted">(Optional)</span></div>
                                            <input type="text" class="form-control" name="usernames[]" value="">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <div class="form-label">Marital Status</div>
                                            <select class="form-control" name="marital_statuses[]">
                                                <option value="">--- Choose One ---</option>
                                                <option v-for="marital_status in marital_statuses" :value="marital_status.id">{{ marital_status.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <div class="form-label pb-2">Gender</div>
                                            <div class="custom-controls-stacked">
                                                <label v-for="gender in genders" class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" :name="'genders['+index+']'" :value="gender.id" v-model="selectedGenders[index]">
                                                    <span class="custom-control-label">{{ gender.name }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="form-label">{{ selectedGenders[index] == 3 ? 'Company Registration No.' : 'Identification Card No.'  }}</div>
                                    <input type="text" class="form-control" style="width: 50%;" name="nrics[]" value="" onBlur="javascript:{this.value = this.value.replace(/[\s)(+-]/g, ''); }">
                                </div>
                                <div class="form-group">
                                    <div class="form-label">Address</div>
                                    <input type="text" class="form-control" name="addresses[]" value="">
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <div class="form-label">Postcode</div>
                                            <input type="text" class="form-control" name="postcodes[]" value="">
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="form-group">
                                            <div class="form-label">City</div>
                                            <input type="text" class="form-control" name="cities[]" value="">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <div class="form-label">State</div>
                                            <select class="form-control" name="states[]">
                                                <option value="">--- Choose One ---</option>
                                                <option v-for="state in states" :value="state.id">{{ state.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="form-group country">
                                            <div class="form-label">Country</div>
                                            <select class="form-control" name="countries[]" v-model="malaysiaId">
                                                <option value="">--- Choose One ---</option>
                                                <option v-for="country in countries" :value="country.id">{{ country.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Home Phone No.</div>
                                            <input type="text" class="form-control" name="phone_homes[]" value="" onBlur="javascript:{this.value = this.value.replace(/[\s)(+-]/g, ''); }">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Mobile Phone No. <span style="color:red;font-weight:bold">*</span></div>
                                            <input type="text" class="form-control" name="phone_mobiles[]" value="" onBlur="javascript:{this.value = this.value.replace(/[\s)(+-]/g, ''); }" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-label">Email</div>
                                    <input type="text" class="form-control" name="emails[]" value="" style="width: 50%;">
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Branch <span style="color:red;font-weight:bold">*</span></div>
                                            <select class="form-control" name="branches[]" v-model="selectedBranch" required>
                                                <option>--- Choose One ---</option>
                                                <option v-for="branch in branches" :value="branch.id">{{ branch.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="donor_ids[]" value="">
                    </div>

                    <!-- list_bank_card -->
                    <div v-if="recurring.donorMode == 'find' && recurring.donor.id && recurring.donor.active_recurrings" class="card-body" id="list_bank_card">
                        <div class="row">
                            <div class="col-3">
                                <h4>List of Subscriptions</h4>
                            </div>
                            <div class="col-9">
                                <div v-for="active_recurring in recurring.donor.active_recurrings" class="col mb-2">
                                    <div>
                                        <div class="d-flex justify-content-start align-items-center">
                                            <span class="font-weight-bold text-lg mr-2">#{{ active_recurring.id }} {{ (active_recurring.campaign ? active_recurring.campaign.name[lang] : '') }}</span>
                                            <span v-if="active_recurring.package" class="badge badge-secondary mr-2">{{ active_recurring.package.name[lang] }}</span><br>
                                            <span v-if="active_recurring.amount" class="text-muted">RM{{ Number(active_recurring.amount).toFixed(0) }}/month</span>
                                        </div>
                                        <div>
                                            <span v-if="active_recurring.bank" class="text-lg">{{ active_recurring.bank.name }}</span>
                                            <span v-if="active_recurring.no_account" class="text-muted">({{ active_recurring.no_account }})</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!recurring.donor.active_recurrings.length">
                                    <span class="text-secondary">No Subscription</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- form_recurring_card -->
                    <div v-if="recurring.donorMode == 'new' || (recurring.donorMode == 'find' && recurring.donor.id)" class="card-body" id="form_recurring_card">
                        <div class="row">
                            <div class="col-3">
                                <h4>Subscription Details</h4>
                            </div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Method <span class="text-muted">(Optional)</span></div>
                                            <select class="form-control custom-select placeholder" name="methods[]">
                                                <option :value="null">Please Pick One...</option>
                                                <option v-for="method in methods" :value="method.id">{{ method.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Sector <span class="text-muted">(Optional)</span></div>
                                            <select class="form-control custom-select placeholder" name="sectors[]">
                                                <option :value="null">Please Pick One...</option>
                                                <option v-for="sector in sectors" :value="sector.id">{{ sector.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Medium <span class="text-muted">(Optional)</span></div>
                                            <select class="form-control custom-select placeholder" name="mediums[]">
                                                <option :value="null">Please Pick One...</option>
                                                <option v-for="medium in mediums" :value="medium.id">{{ medium.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="form-label">Frequency <span class="text-muted">(Optional)</span></div>
                                            <select class="form-control custom-select placeholder" name="frequencies[]">
                                                <option :value="null">Please Pick One...</option>
                                                <option v-for="frequency in frequencies" :value="frequency.id">{{ frequency.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-label">Bank <span class="text-muted">(optional)</span></div>
                                    <select class="form-control" name="banks[]" style="width: 75%;">
                                        <option value="">--- Choose One ---</option>
                                        <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <div class="form-label">Bank Account Number <span class="text-muted">(optional)</span></div>
                                    <input class="form-control" name="no_accounts[]" style="width: 50%;" type="text">
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-label">Start Date <span class="text-red">*</span></div>
                                            <input type="date" class="form-control" id="start_date" name="start_date" v-model="startDate" @change="setEndDate" required>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-label">End Date <span class="text-red">*</span></div>
                                            <input type="date" class="form-control" id="end_date" name="end_date" v-model="endDate" required readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-label">Donation Amount (RM)<span class="text-red">*</span></div>
                                    <input type="number" class="form-control w-50" name="amounts[]" min=5 v-model="recurring.amount" @change="calculateTotalAmount" required>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card mt-4" v-if="mode == 'fillDonor'">
            <div class="card-body">
                <div class="row">
                    <div class="col-7">
                        <span v-if="totalAmount != packageAmount">Total Donation: <span class="text-danger">{{ totalAmount }}</span> / {{ packageAmount }}</span>
                        <span v-else>Donation Amount: {{ totalAmount }} / {{ packageAmount }}</span>
                        <span class="text-danger">
                            <i v-if="totalAmount < packageAmount && totalAmount != '0.00'">* Total amount not enough to form a group subscription</i>
                            <i v-else-if="totalAmount > packageAmount" class="text-danger">* Total amount exceeds the required amount</i>
                        </span>
                    </div>
                    <div class="col-5">
                        <button type="submit" class="btn btn-primary float-right" :disabled="totalAmount != packageAmount">Add Subscriptions</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                campaignId: this.kafalahId,
                packageId: null,
                numberOfDonors: 2,
                mode: 'fillPackage',
                recurrings: [],
                totalAmount: '0.00',
                packageAmount: '0.00',
                package: {},
                selectedGenders: [],
                selectedBranch: this.authUser.branch_id,
                startDate: null,
                endDate: null,
            };
        },
        props: [
            'lang',
            'presalutations',
            'salutations',
            'marital_statuses',
            'genders',
            'states',
            'countries',
            'branches',
            'banks',
            'campaignPackages',
            'kafalahId',
            'authUser',
            'malaysiaId',
            'methods',
            'sectors',
            'mediums',
            'frequencies',
        ],
        computed: {
        },
        methods: {
            getPackageAmount() {
                try {
                    this.package = this.campaignPackages.find(p => p.id == this.packageId);
                    this.packageAmount = this.package.amount;
                } catch (e) {
                    console.log('error ', e);
                }
            },
            setNumberOfDonor() {
                for (var i = 0; i < this.numberOfDonors; i++) {
                    this.recurrings.push({
                        donorMode: 'new',
                        amount: '5',
                        donor: {},
                    })
                }
                this.mode = 'fillDonor';
                this.getPackageAmount();
                this.calculateTotalAmount();
            },
            resetNumberOfDonor() {
                const options = {title: 'Do you want to proceed to reset all form?', cancelLabel: 'Cancel reset', okLabel: 'Reset form'}
                this.$dialogs.confirm('All the detail in form will be cleared and need to be refill', options)
                .then(res => {
                    if (res.ok) {
                        this.packageId = null;
                        this.numberOfDonors = '2';
                        this.mode = 'fillPackage';
                        this.recurrings.length = null;
                        this.packageAmount = '0.00';
                        this.package = {};
                    }
                })
            },
            calculateTotalAmount() {
                var minAmount = 5;
                for (var index = this.recurrings.length - 1; index >= 0; index--) {
                    if (this.recurrings[index].amount < minAmount) {
                        this.recurrings[index].amount = minAmount;
                    }
                }
                return this.totalAmount = this.recurrings.reduce((sum, recurring) => {
                    if (recurring.amount) {
                        return parseFloat(recurring.amount) + parseFloat(sum);
                    } else {
                        return parseFloat(sum);
                    }
                }, 0).toFixed(2)
            },
            handleChangeDonor(donor, index) {
                this.recurrings[index].donor = donor;
            },
            setEndDate() {
                this.endDate = moment(this.startDate).add(1, 'years').subtract(1, 'days').format("YYYY-MM-DD");
            }
        },
        mounted() {
        }
    }
</script>