
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./plugins/toastr');
require('./plugins/selectize');

window.Vue = require('vue');
window.pluralize = require('pluralize')
window.moment = require('moment-timezone');
moment.tz.setDefault('UTC');

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

window.selectize = require('selectize');
window.select2 = require('select2');

import VueMousetrap from 'vue-mousetrap'
Vue.use(VueMousetrap)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import Vue from 'vue'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'
Vue.use(SlimDialog)

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
    iconPack : 'fontawesome'
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

////////////////////////////////////////////////////////////
// VUE COMPONENTS
////////////////////////////////////////////////////////////

// Kewangan
Vue.component('counter', require('./components/Counter.vue').default);
Vue.component('Datepicker', require('./components/Datepicker.vue').default);
Vue.component('campaign-form', require('./components/CampaignForm.vue').default);
Vue.component('post-form', require('./components/PostForm.vue').default);
Vue.component('visitor-package-picker', require('./components/VisitorPackagePicker.vue').default);
Vue.component('package-form', require('./components/PackageForm.vue').default);
Vue.component('currency-input', require('./components/CurrencyInput.vue').default);
Vue.component('content-editor', require('./components/ContentEditor.vue').default);
Vue.component('create-new-bill', require('./components/CreateNewBill.vue').default);
Vue.component('edit-bill', require('./components/EditBill.vue').default);
Vue.component('create-new-recurring', require('./components/CreateNewRecurring.vue').default);
Vue.component('create-new-recurring-bill', require('./components/CreateNewRecurringBill.vue').default);
Vue.component('create-new-recurring-group', require('./components/CreateNewRecurringGroup.vue').default);
Vue.component('bill-filter', require('./components/BillFilter.vue').default);
Vue.component('recurring-filter', require('./components/RecurringFilter.vue').default);
Vue.component('donor-search', require('./components/DonorSearch.vue').default);
Vue.component('form-select', require('./components/FormSelect.vue').default);
Vue.component('distribution-slider', require('./components/DistributionSlider.vue').default);
// Vue.component('sponsor-form', require('./components/SponsorForm.vue').default);

let bus = new Vue();
Vue.prototype.$bus = bus;
Vue.prototype.$http = window.axios;

const app = new Vue({
    el: '#app',
});
