<template>
    <input type="text" class="form-control text-right py-0" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"/>
</template>

<script>
    export default {
        data() {
            return {
                isInputActive: false
            }
        },
        props: ['name', 'value'],
        computed: {
            displayValue: {
                get: function() {
                    if (this.isInputActive) {
                        // Cursor is inside the input field. unformat display value for user
                        return this.value.toString()
                    } else {
                        // User is not modifying now. Format display value for user interface
                        return "RM " + this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    }
                },
                set: function(modifiedValue) {
                    // Recalculate value after ignoring "$" and "," in user input
                    let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                    // Ensure that it is not NaN
                    if (isNaN(newValue)) {
                        newValue = 0
                    }
                    // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                    // $emit the event so that parent component gets it
                    this.$emit('input', newValue)
                    this.$emit('change-currency-input', newValue);
                }
            }
        },
        methods: {
        },
        mounted() {
        }
    }
</script>