<template>
    <div>
        <input type="hidden" name="editingMode" :value="editingMode">
        <div class="form-label">Content <span class="text-red">*</span></div>
        <div class="form-label">
            <button @click="toggleEditingMode($event)" class="pl-0 btn btn-link" style="text-decoration: underline !important">{{ toggleEditingModeLabel }}</button><span class="text-secondary">(Click Update Page before exit the Edit Mode)</span>
        </div>
        <div v-show="editingMode == 'visual'">
            <textarea class="form-control" rows="20" name="visual" id="visual" v-model="content"></textarea>
        </div>
        <div v-show="editingMode == 'code'">
            <textarea class="form-control" rows="20" name="code" id="code" v-model="content"></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        props: ['content', 'editingMode'],
        computed: {
            toggleEditingModeLabel() {
                return this.editingMode == 'visual' ? 'Change Mode to Code Editor' : 'Change Mode to Visual Editor';
            }
        },
        methods: {
            toggleEditingMode(event) {
                event.preventDefault();

                if (confirm('Adakah anda telah kemaskini halaman? Sebarang perubahan yang belum disimpan akan hilang sekiranya anda meneruskan')) {
                } else {
                    return;
                }

                if (this.editingMode == 'visual') {
                    this.goToCode();
                } else {
                    this.goToVisual();
                }
            },
            goToVisual() {
                window.location.href = window.location.pathname + '?mod_suntingan=visual';
            },
            goToCode() {
                window.location.href = window.location.pathname + '?mod_suntingan=code';
            }
        }
    }
</script>