<template>
    <div class="border-bottom">
        <!-- check_donor_id_card -->
        <div class="card-body" id="check_donor_id_card">
            <div class="row">
                <div class="col-3">
                    <h4>Search Donor</h4>
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <select @keydown.enter.prevent="findDonor" name="check_donor_id" :id="'check_donor_id' + (uniqueKey != '' ? `_${uniqueKey}` : '')">
                                    <option value="" selected>Please enter the donor name, no. identification card or email...</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- detail_donor_card -->
        <div v-if="donor.id" class="card-body" id="detail_donor_card">
            <div class="row">
                <div class="col-3">
                    <h4>Donor Details</h4>
                </div>
                <div class="col-9">
                    <input type="hidden" :name="getInputName()" id="lbl_id" v-model="donor.id">

                    <div class="form-group">
                        <div class="form-label">Fullname</div>
                        <span id="lbl_fullname">{{ (donor.salutation ? donor.salutation.name : '')+" "+donor.fullname }}</span>
                    </div>
                    <div class="form-group">
                        <div class="form-label" v-if="donor.gender_id == 3">Company Registration No.</div>
                        <div class="form-label" v-else>No. Identification Card</div>
                        <span id="lbl_nric">{{ donor.nric }}</span>
                    </div>
                    <div class="form-group">
                        <div class="form-label">Branch</div>
                        <span class="badge badge-secondary text-sm">{{ getDonorBranchName(donor) }}</span>
                    </div>
                    <div class="form-group">
                        <div class="form-label">Address</div>
                        <span id="lbl_address">{{ donor.address+', '+donor.postcode+', '+donor.city+', '+(donor.state ? donor.state.name : '') }}</span>
                    </div>
                    <div class="form-group">
                        <div class="form-label">Country</div>
                        <span id="lbl_country">{{ (donor.country ? donor.country.name : '') }}</span>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <div class="form-label">No. Home Phone</div>
                                <span id="lbl_phone_home">{{ donor.phone_home ? donor.phone_home : '-' }}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <div class="form-label">No. Mobile phone</div>
                                <span id="lbl_phone_mobile">{{ donor.phone_mobile ? donor.phone_mobile : '-' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-label">Email</div>
                        <span id="lbl_email">{{ donor.email }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                donor: this.initDonor ? this.initDonor : { id: '' },
                selectizeDonor: null,
            };
        },
        props: {
            initDonor: {
                default: null
            },
            uniqueKey: {
                default: ''
            }
        },
        computed: {
        },
        methods: {
            async findDonor() {
                try {
                    const response = await axios.get(`/admin/donors/${this.donor.id}`);

                    this.donor = response.data;
                    this.$emit('changeDonor', this.donor);
                } catch (e) {
                    console.log('error ', e);
                }
            },
            clearOptions() {
                this.selectizeDonor[0].selectize.close();
                this.selectizeDonor[0].selectize.clearOptions();
            },
            getInputName() {
                return this.uniqueKey != '' ? 'donor_ids[]' : 'donor_id';
            },
            getDonorBranchName(donor) {
                return _.get(donor, 'branch.name');
            },
            getDonorText(donor) {
                var nric = donor.nric ? '' + donor.nric + ' - ' : '';
                var fullname = donor.fullname ? donor.fullname + ' ' : '';
                var email = donor.email ? '(' + donor.email + ')' : '';
                
                return nric + fullname + email;
            },
            getDonorId() {
                return _.get(this.initDonor, 'id');
            }
        },
        mounted() {
            var that = this;
            // if one page has multi DonorSearch, uniqueKey will make things work
            var key = this.uniqueKey != '' ? `_${this.uniqueKey}` : '';

            // SELECT2
            var $search_select = $(`#check_donor_id${key}`).select2({
                ajax: {
                    url: '/admin/donors',
                    dataType: 'json',
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page || 1,
                            override: !params.term ? 'empty' : ''
                        }
                    },
                    processResults: function (data, params) {
                        var items = data.items.data.map(function (donor) {
                            return {
                                id: donor.id,
                                text: that.getDonorText(donor)
                            }
                        });

                        params.page = params.page || 1;

                        return {
                            results: items,
                            pagination: {
                                more: (params.page * 30) < data.total_count
                            }
                        };
                    },
                    cache: true
                },
                width: '100%'
            })

            $search_select.on('select2:select', function (e) {
                Vue.set(that.donor, 'id', e.params.data.id);
                that.findDonor();
            });

            if (this.getDonorId()) {
                $search_select.append(new Option(this.getDonorText(this.initDonor), this.getDonorId(), true, true)).trigger("change");
            }

            // SELECTIZE
            // this.selectizeDonor = $(`#check_donor_id${key}`).selectize({
            //     valueField: 'id',
            //     labelField: 'fullname',
            //     searchField: ['nric', 'fullname', 'email'],
            //     options: [],
            //     create: false,
            //     plugins: ['remove_button'],
            //     render: {
            //         option: function(donor, escape) {
            //             var fullname = donor.fullname ? escape(donor.fullname) : '';
            //             var details = [];
            //             if (donor.nric) {
            //                 details.push(escape(donor.nric));
            //             }
            //             if (donor.email) {
            //                 details.push(escape(donor.email));
            //             }
            //             if (donor.branch) {
            //                 details.push(escape(_.get(donor, 'branch.name')));
            //             }
            //             return '<div class="option">' +
            //                 fullname + ' - ' +
            //                 details.join(' | ') +
            //                 '</div>';
            //         },
            //         item: function(donor, escape) {
            //             var fullname = donor.fullname ? escape(donor.fullname) : '';
            //             var details = [];
            //             if (donor.nric) {
            //                 details.push(escape(donor.nric));
            //             }
            //             if (donor.email) {
            //                 details.push(escape(donor.email));
            //             }
            //             if (donor.branch) {
            //                 details.push(escape(_.get(donor, 'branch.name')));
            //             }
            //             return '<div class="option">' +
            //                 fullname + ' - ' +
            //                 details.join(' | ') +
            //                 '</div>';
            //         }
            //     },
            //     load: function(query, callback) {
            //         var override = '';
            //         if(!query.length) override = '&override=empty';
            //         $.ajax({
            //             url: '/admin/donors?q=' + query + override,
            //             type: 'GET',
            //             error: function() {
            //                 callback();
            //             },
            //             success: function(res) {
            //                 if (!res.length) {
            //                     that.clearOptions();
            //                 } else {
            //                     callback(res);
            //                 }
            //             }
            //         });
            //     },
            //     onClear: function (value) {
            //         that.clearOptions();
            //     },
            //     onItemAdd: function (value, $item) {
            //         Vue.set(that.donor, 'id', value);
            //         that.findDonor();
            //     }
            // });

            // // prevent submitting form when clicking enter before options loaded
            // $(`#check_donor_id${key}-selectized`).keydown(function (e) {
            //     if (e.keyCode == 13) {
            //         e.preventDefault();
            //         return false;
            //     }
            // });

            // if (_.get(this.initDonor, 'id')) {
            //     this.selectizeDonor[0].selectize.addOption(this.initDonor);
            //     this.selectizeDonor[0].selectize.setValue(this.initDonor.id, false);
            // }
        }
    }
</script>