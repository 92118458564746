<template>
<form :action="route" method="GET">
<div class="card mt-4 mb-2 px-0 pt-3 pb-0">
    <div class="row mt-0 px-4">
        <div class="col-6">

            <div class="form-group mb-0">
                <p class="form-label text-gray">Donors</p>
                <select name="donor_id" id="donor_id">
                    <option value="" selected>Please enter the donor name, no. identification card or email...</option>
                </select>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Branch</div>
                <select id="branch_id" v-model="filter.branchId" :settings="settings" placeholder="Choose a branch" name="branch_id">
                    <option v-for="branch in branches" :value="branch.id">{{ branch.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Campaign</div>
                <select id="campaign_id" v-model="filter.campaignId" :settings="settings" placeholder="Choose a Campaign" name="campaign_id">
                    <option v-for="campaign in campaigns" :value="campaign.id"><pre>{{ campaign.name[lang] }}</pre></option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mt-0 px-4">
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Method</div>
                <select id="method_id" v-model="filter.methodId" :settings="settings" placeholder="Choose a method" name="method_id">
                    <option v-for="method in methods" :value="method.id">{{ method.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Sector</div>
                <select id="sector_id" v-model="filter.sectorId" :settings="settings" placeholder="Choose a sector" name="sector_id">
                    <option v-for="sector in sectors" :value="sector.id">{{ sector.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Medium</div>
                <select id="medium_id" v-model="filter.mediumId" :settings="settings" placeholder="Choose a medium" name="medium_id">
                    <option v-for="medium in mediums" :value="medium.id">{{ medium.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Status</div>
                <select id="status" v-model="filter.status" :settings="settings" placeholder="Choose a status" name="status">
                    <option v-for="status in statuses" :value="status">{{ capitalize(status) }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mt-0 px-4">
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Remark</div>
                <input type="text" class="form-control" name="remark" v-model="filter.remark">
            </div>
        </div>
        <div class="col-3">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Amount (RM)</div>
                <input type="number" class="form-control" name="amount" v-model="filter.amount">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group mb-4">
                <div class="form-label text-gray">Period</div>
                <datepicker
                    id="datepicker"
                    class="mt-2 mb-0"
                    :start="startDate"
                    :use-vuex="false"
                    :end="endDate">
                </datepicker>
            </div>
        </div>
    </div>
    <div class="card-body border-top py-2 px-4 d-flex justify-content-end">
        <button type="submit" class="btn btn-secondary"><i class="fe fe-filter mr-2"></i>Filter</button>
    </div>
</div>
</form>
</template>

<style>
    a.remove-single {
        text-decoration: none;
    }
</style>

<script>
    export default {
        data() {
            return {
                startDate: this.initStartDate,
                endDate: this.initEndDate,
                filter: {
                    search: this.request.search || '',
                    branchId: this.request.branch_id || '',
                    campaignId: this.request.campaign_id || '',
                    methodId: this.request.method_id || '',
                    sectorId: this.request.sector_id || '',
                    mediumId: this.request.medium_id || '',
                    status: this.request.status || '',
                    remark: this.request.remark || '',
                    amount: this.request.amount || '',
                },
                settings: {
                    create: false,
                    sortField: 'text',
                    plugins: ['remove_button'],
                }
            };
        },
        props: {
            initDonor: {
                default: null
            },
            route: String,
            branches: Array,
            campaigns: Array,
            methods: Array,
            sectors: Array,
            mediums: Array,
            statuses: Array,
            initStartDate: String,
            initEndDate: String,
            lang: String,
            request: Array|Object
        },
        computed: {
        },
        methods: {
            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            clearOptions() {
                this.selectizeDonor[0].selectize.close();
                this.selectizeDonor[0].selectize.clearOptions();
            }
        },
        mounted() {
            let settings = {
                create: false,
                sortField: 'text',
                plugins: ['remove_button'],
            };

            $('#search').selectize({
                create: false,
                plugins: ['remove_button'],
            });
            $('#branch_id').selectize(settings);
            $('#campaign_id').selectize(settings);
            $('#method_id').selectize(settings);
            $('#sector_id').selectize(settings);
            $('#medium_id').selectize(settings);
            $('#status').selectize(settings);

            // selectize for donor
            var that = this;

            this.selectizeDonor = $(`#donor_id`).selectize({
                valueField: 'id',
                labelField: 'fullname',
                searchField: ['nric', 'fullname', 'email'],
                options: [],
                create: false,
                plugins: ['remove_button'],
                render: {
                    option: function(donor, escape) {
                        var nric = donor.nric ? '' + escape(donor.nric) + ' - ' : '';
                        var fullname = donor.fullname ? escape(donor.fullname) + ' ' : '';
                        var email = donor.email ? '(' + escape(donor.email) + ')' : '';
                        return '<div class="option">' +
                            nric +
                            fullname +
                            email +
                            '</div>';
                    },
                    item: function(donor, escape) {
                        var nric = donor.nric ? '' + escape(donor.nric) + ' - ' : '';
                        var fullname = donor.fullname ? escape(donor.fullname) + ' ' : '';
                        var email = donor.email ? '(' + escape(donor.email) + ')' : '';
                        return '<div class="option">' +
                            nric +
                            fullname +
                            email +
                            '</div>';
                    }
                },
                load: function(query, callback) {
                    var override = '';
                    if(!query.length) override = '&override=empty';
                    $.ajax({
                        url: '/admin/donors?q=' + query + override,
                        type: 'GET',
                        error: function() {
                            callback();
                        },
                        success: function(res) {
                            if (!res.length) {
                                that.clearOptions();
                            } else {
                                callback(res);
                            }
                        }
                    });
                },
                onClear: function (value) {
                    that.clearOptions();
                },
            });

            // prevent submitting form when clicking enter before options loaded
            $(`#donor_id-selectized`).keydown(function (e) {
                if (e.keyCode == 13) {
                    e.preventDefault();
                    return false;
                }
            });

            if (_.get(this.initDonor, 'id')) {
                this.selectizeDonor[0].selectize.addOption(this.initDonor);
                this.selectizeDonor[0].selectize.setValue(this.initDonor.id, false);
            }
        }
    }
</script>