<template>
    <div>
        <form ref="form" :action="routeStore" method="POST">
            <input type="hidden" name="_token" :value="csrf" />
            <div class="card mt-4">

                <div :class="`card-body ${donorIdentity != 'anonymous' ? 'border-bottom' : ''}`">
                    <div class="row">
                        <div class="col-md-3 d-none d-sm-block"><h4>Donor Identity</h4></div>
                        <div class="col-md-3 d-block d-sm-none text-center"><h4>Donor Identity</h4></div>

                        <div class="col-12 col-md-5">
                            <div class="form-group">
                                <div class="custom-controls-stacked">
                                    <label class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" name="donor_identity" value="real" v-model="donorIdentity">
                                        <div class="custom-control-label">Personal Details</div>
                                    </label>
                                    <label class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" name="donor_identity" value="anonymous" v-model="donorIdentity">
                                        <div class="custom-control-label">Anonymous/Incognito</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <donor-search
                    v-if="donorIdentity == 'real'"
                    :init-donor="donor"
                    @changeDonor="handleChangeDonor($event)"
                >
                </donor-search>

                <!-- create_bill -->
                <div v-if="donor.id || donorIdentity == 'anonymous'" class="card-body" id="create_bill">
                    <div class="row">
                        <div class="col-3">
                            <h4>Donation Details</h4>
                        </div>
                        <div class="col-9">
                            <div class="form-group">
                                <div class="form-label">Branch <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="branch" style="width: 40%;" v-model="selectedBranch" required>
                                    <option value="">--- Choose one ---</option>
                                    <option v-for="branch in branches" :value="branch.id">{{ branch.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Campaign <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="campaign" style="width: 40%;" v-model="campaignId" required>
                                    <option value="">--- Choose one ---</option>
                                    <option v-for="campaign in campaigns.filter(c => donorIdentity == 'real' || !c.isAnonymousDisallowed)" :value="campaign.id">{{ campaign.name[lang] }}</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="campaignId">
                                <div class="form-label">Package <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="package" style="width: 40%;" v-model="packageId" required @change="handlePackageChange($event)">
                                    <option value="">--- Choose one ---</option>
                                    <option v-for="campaignPackage in currentPackages.filter(p => p.campaign_id == campaignId)" :value="campaignPackage.id">{{ campaignPackage.name[lang] }} <span v-if="campaignPackage.is_open == 0 && campaignPackage.amount">(RM{{ campaignPackage.amount }})</span> <span v-if="campaignPackage.archived_at">(Archived)</span></option>
                                </select>
                            </div>

                            <div class="form-group" v-if="packageId && !isPackageOpenPrice(packageId)">
                                <div class="form-label">Quantity</div>
                                <counter
                                    class="w-25"
                                    @change-counter="handleChangeCounter($event)"
                                    name="quantity"
                                    :init-counter="quantity"
                                >
                                </counter>
                            </div>

                            <input v-if="packageId && isPackageOpenPrice(packageId)" type="hidden" name="quantity" value="1">

                            <div class="form-group">
                                <div class="form-label">Donation Amount (RM) <span class="text-red">*</span></div>
                                <money class="form-control w-50" id="amount" :readonly="! isPackageOpenPrice(packageId)" v-model="amount" v-bind="money"></money>
                                <input type="hidden" name="amount" v-model="amount">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Payment Status <span class="text-red">*</span></label>
                                <div class="selectgroup w-50">
                                    <label class="selectgroup-item">
                                        <input type="radio" class="selectgroup-input" name="status" value="0">
                                        <span class="selectgroup-button">In Process</span>
                                    </label>
                                    <label class="selectgroup-item">
                                        <input type="radio" class="selectgroup-input" name="status" value="1" checked>
                                        <span class="selectgroup-button">Paid</span>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Method <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="method" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="method in methods" :value="method.id">{{ method.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Sector <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="sector" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="sector in sectors" :value="sector.id">{{ sector.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Medium <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="medium" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="medium in mediums" :value="medium.id">{{ medium.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">{{ transactionDateLabel }} <span class="text-red">*</span></div>
                                <input type="date" class="form-control w-50" id="deduction_date" name="deduction_date" v-model="initDeductionDate" required>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Billplz ID</div>
                                <input type="text" name="billplz_id" class="form-control w-50" value="">
                            </div>

                            <div class="form-group">
                                <div class="form-label">Remark </div>
                                <textarea class="form-control" name="remark" id="remark" rows="3"></textarea>
                            </div>

                            <div v-if="donorIdentity == 'real'" class="form-group">
                                <input type="checkbox" id="send_email" name="send_email" value="true" v-model="sendEmail">
                                <span class="ml-1 font-italic">Send receipt to donor's email.</span>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-9 offset-3 mt-6">
                            <button :disabled="isSubmitting" @click="submit()" type="button" class="btn btn-primary">Add Donation</button>
                        </div>
                    </div>
                </div>

            </div> <!-- end card -->
        </form>
    </div>
</template>
<script>
    import {Money} from 'v-money'
    export default {
        components: {Money},
        data() {
            return {
                donorIdentity: 'real',
                donor: this.initDonor ? JSON.parse(this.initDonor) : { id: '' },
                campaignId: '',
                packageId: '',
                currentPackages: this.campaignPackages,
                amount: 0,
                transactionDateLabel: 'Transaction Date',
                sendEmail: false,
                selectedBranch: '',
                isSubmitting: false,
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: 'RM ',
                    suffix: '',
                    precision: 2,
                    masked: false
                },
                quantity: 1,
                initDeductionDate: this.getTodayDate()
            };
        },
        props: [
            'lang',
            'routeStore',
            'csrf',
            'initDonor',
            'campaigns',
            'campaignPackages',
            'branches',
            'methods',
            'sectors',
            'mediums',
            'authUser',
        ],
        watch: {
        },
        methods: {
            init() {
                if (this.authUser.branch_id) {
                    this.selectedBranch = this.authUser.branch_id;
                }
            },
            isPackageOpenPrice(packageId) {
                let pkg = this.currentPackages.find(p => p.id == packageId);
                return pkg ? pkg.is_open == 1 : false;
            },
            handleChangeDonor(donor) {
                this.donor = donor;
            },
            getTodayDate() {
                return moment.tz("Asia/Kuala_Lumpur").format('YYYY-MM-DD');
            },
            submit() {
                this.isSubmitting = true;
                this.$refs.form.submit();
            },
            handlePackageChange($event) {
                // always reset quantity if package changed
                this.quantity = 1;
                let pkg = this.currentPackages.find(p => p.id == $event.target.value)
                if (pkg && pkg.is_open == 1) {
                    this.amount = 0;
                } else if (pkg && pkg.is_open == 0) {
                    this.amount = pkg.amount * this.quantity;
                }
            },
            handleChangeCounter(count) {
                this.quantity = count;
                if (this.isPackageOpenPrice(this.packageId)) {
                    return;
                }

                let pkg = this.currentPackages.find(p => p.id == this.packageId);
                this.amount = pkg.amount * this.quantity;
            }
        },
        mounted() {
            this.init();
        }
    }
</script>