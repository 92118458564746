<template>
    <div>
        <form :action="routeUpdate" method="POST">
            <input type="hidden" name="_method" value="PUT">
            <input type="hidden" name="_token" :value="csrf">

            <div class="card mt-4">

                <div class="card-body" id="detail_donor_card">
                    <div class="row">
                        <div class="col-3">
                            <h4>Donor Details</h4>
                        </div>
                        <div v-if="donor" class="col-9">
                            <div class="form-group">
                                <div class="form-label">Fullname</div>
                                <span id="lbl_fullname">{{ (donor.salutation ? donor.salutation.name : '')+" "+donor.fullname }}</span>
                            </div>
                            <div class="form-group">
                                <div class="form-label" v-if="donor.gender_id == 3">Company Registration No.</div>
                                <div class="form-label" v-else>No. Identification Card</div>
                                <span id="lbl_nric">{{ donor.nric }}</span>
                            </div>
                            <div class="form-group">
                                <div class="form-label">Branch</div>
                                <span class="badge badge-secondary text-sm">{{ getDonorBranchName(donor) }}</span>
                            </div>
                            <div class="form-group">
                                <div class="form-label">Address</div>
                                <span id="lbl_address">{{ donor.address+', '+donor.postcode+', '+donor.city+', '+(donor.state ? donor.state.name : '') }}</span>
                            </div>
                            <div class="form-group">
                                <div class="form-label">Country</div>
                                <span id="lbl_country">{{ (donor.country ? donor.country.name : '') }}</span>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <div class="form-label">No. Home Phone</div>
                                        <span id="lbl_phone_home">{{ donor.phone_home ? donor.phone_home : '-' }}</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <div class="form-label">No. Mobile phone</div>
                                        <span id="lbl_phone_mobile">{{ donor.phone_mobile ? donor.phone_mobile : '-' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-label">Email</div>
                                <span id="lbl_email">{{ donor.email }}</span>
                            </div>
                        </div>
                        <div v-else class="col-9">
                            <div class="form-group">
                                <div class="form-label">Anonymous</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- create_bill -->
                <div class="card-body" id="create_bill">
                    <div class="row">
                        <div class="col-3">
                            <h4>Donation Details #{{ bill.id }}</h4>
                        </div>
                        <div class="col-9">
                            <div class="form-group">
                                <div class="form-label">Branch <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="branch" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="branch in branches" :value="branch.id" :selected="branch.id == bill.branch_id">{{ branch.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Campaign <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="campaign" style="width: 40%;" v-model="campaignId" required>
                                    <option value="">--- Choose one ---</option>
                                    <option v-for="campaign in campaigns" :value="campaign.id">{{ campaign.name[lang] }}</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="campaignId">
                                <div class="form-label">Package <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="package" style="width: 40%;" v-model="packageId" required @change="handlePackageChange($event)">
                                    <option value="null">--- Choose one ---</option>
                                    <option v-for="campaignPackage in currentPackages.filter(p => p.campaign_id == campaignId)" :value="campaignPackage.id">{{ campaignPackage.name[lang] }} <span v-if="campaignPackage.is_open == 0 && campaignPackage.amount">(RM{{ campaignPackage.amount }})</span> <span v-if="campaignPackage.archived_at">(Archived)</span></option>
                                </select>
                            </div>

                            <div class="form-group" v-if="packageId && !isPackageOpenPrice(packageId)">
                                <div class="form-label">Quantity</div>
                                <counter
                                    class="w-25"
                                    @change-counter="handleChangeCounter($event)"
                                    name="quantity"
                                    :init-counter="quantity"
                                >
                                </counter>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Donation Amount (RM) <span class="text-red">*</span></div>
                                <money class="form-control w-50" id="amount" :readonly="! isPackageOpenPrice(packageId)" v-model="amount" v-bind="money"></money>
                                <input type="hidden" name="amount" v-model="amount">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Payment Status <span class="text-red">*</span></label>
                                <div class="selectgroup w-50">
                                    <label class="selectgroup-item">
                                        <input type="radio" class="selectgroup-input" name="status" value="0" v-model="billStatus">
                                        <span class="selectgroup-button">In Process</span>
                                    </label>
                                    <label class="selectgroup-item">
                                        <input type="radio" class="selectgroup-input" name="status" value="1" v-model="billStatus">
                                        <span class="selectgroup-button">Paid</span>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Method <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="method" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="method in methods" :value="method.id" :selected="method.id == bill.method_id">{{ method.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Sector <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="sector" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="sector in sectors" :value="sector.id" :selected="sector.id == bill.sector_id">{{ sector.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Medium <span class="text-red">*</span></div>
                                <select class="custom-select w-50" name="medium" style="width: 40%;" required>
                                    <option>--- Choose one ---</option>
                                    <option v-for="medium in mediums" :value="medium.id" :selected="medium.id == bill.medium_id">{{ medium.name }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="form-label">{{ transactionDateLabel }} <span class="text-red">*</span></div>
                                <input type="date" class="form-control w-50" id="deduction_date" name="deduction_date" :value="getBillDate(bill)" required>
                            </div>

                            <div class="form-group">
                                <div class="form-label">Billplz ID</div>
                                <input type="text" name="billplz_id" class="form-control w-50" :value="bill.billplz_id">
                            </div>

                            <div class="form-group">
                                <div class="form-label">Remark </div>
                                <textarea class="form-control" name="remark" id="remark" rows="3" :disabled="isJson(bill.remark)" v-html="displayBillRemark(bill)"></textarea>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 offset-3 mt-6">
                            <button type="submit" class="btn btn-primary">Update Donation</button>
                        </div>
                        <div class="col-3 mt-6 text-right">
                            <button type="button" class="btn btn-link text-red" @click="deleteBill">Delete</button>
                        </div>
                    </div>
                </div>

            </div> <!-- end card -->
        </form>
        <form :action="routeDelete" id="deleteForm" method="POST">
            <input type="hidden" name="_method" value="DELETE">
            <input type="hidden" name="_token" :value="csrf">
        </form>
    </div>
</template>
<script>
    import {Money} from 'v-money'
    export default {
        components: {Money},
        data() {
            return {
                campaignId: this.bill.campaign_id,
                packageId: this.order.package_id,
                currentPackages: this.campaignPackages,
                amount: this.bill.amount,
                transactionDateLabel: 'Transaction Date',
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: 'RM ',
                    suffix: '',
                    precision: 2,
                    masked: false
                },
                quantity: 1,
                billStatus: this.bill.is_paid
            };
        },
        props: [
            'lang',
            'routeUpdate',
            'routeDelete',
            'csrf',
            'donor',
            'order',
            'campaigns',
            'campaignPackages',
            'branches',
            'methods',
            'sectors',
            'mediums',
            'bill'
        ],
        watch: {
        },
        methods: {
            isPackageOpenPrice(packageId) {
                let pkg = this.currentPackages.find(p => p.id == packageId);
                return pkg ? pkg.is_open == 1 : false;
            },
            handleChangeDonor(donor) {
                this.donor = donor;
            },
            handlePackageChange($event) {
                // always reset quantity if package changed
                this.quantity = 1;
                let pkg = this.currentPackages.find(p => p.id == $event.target.value)
                if (pkg && pkg.is_open == 1) {
                    this.amount = 0;
                } else if (pkg && pkg.is_open == 0) {
                    this.amount = pkg.amount * this.quantity;
                }
            },
            handleChangeCounter(count) {
                this.quantity = count;
                if (this.isPackageOpenPrice(this.packageId)) {
                    return;
                }

                let pkg = this.currentPackages.find(p => p.id == this.packageId);
                this.amount = pkg.amount * this.quantity;
            },
            getBillDate(bill) {
                if (bill.deduction_date) {
                    return moment.tz(bill.deduction_date, "Asia/Kuala_Lumpur").format('YYYY-MM-DD');
                } else {
                    return moment.tz("Asia/Kuala_Lumpur").format('YYYY-MM-DD');
                }
            },
            isJson(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            displayBillRemark(bill) {
                if (this.isJson(this.bill.remark)) {
                    let remark = JSON.parse(this.bill.remark);
                    return _.get(remark, 'korbanNames', []).join('\n');
                } else {
                    return bill.remark;
                }
            },
            deleteBill() {
                if (confirm("Are you sure you want to delete this bill?")) {
                    document.getElementById("deleteForm").submit();
                }
            },
            getDonorBranchName(donor) {
                return _.get(donor, 'branch.name');
            }
        },
        mounted() {
        }
    }
</script>