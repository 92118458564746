<template>
<div>
    <div class="d-flex align-items-center justify-content-between mt-4">
        <a :href="routeBack" class="btn btn-secondary">
            <i class="fe fe-chevron-left mr-2"></i>
            {{ trans['Kembali'] }}
        </a>
        <div class="form-group mb-0">
            <div class="selectgroup">
                <label class="selectgroup-item mb-0">
                    <input type="radio" name="lang" value="ms" class="selectgroup-input" v-model="lang">
                    <span id="post-language-button-ms" :class="`selectgroup-button post-language-button ${getContentValidity('ms') ? 'content-valid' : ''}`">MS</span>
                </label>
                <label class="selectgroup-item mb-0">
                    <input type="radio" name="lang" value="en" class="selectgroup-input" v-model="lang">
                    <span id="post-language-button-en" :class="`selectgroup-button post-language-button ${getContentValidity('en') ? 'content-valid' : ''}`">EN</span>
                </label>
            </div>
        </div>
    </div>
    <div class="card mt-4">
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <h4>Post Details</h4>
                </div>
                <div class="col-9">
                    <div class="form-group">
                        <div class="form-label">Title <span class="text-red">*</span></div>
                        <input type="text" class="form-control" :name="`title[${lang}]`" dusk="title-input" id="title" placeholder="Majlis Amal Aman palestin" v-model="post.title[lang]">
                    </div>

                    <span v-if="initPost && initPost.id" class="text-muted d-flex align-items-start"><span>URL:</span> <a target="_blank" style="margin-top: -0.1rem !important;" class="btn btn-link pl-2 pb-2 pt-0 text-left" :href="`/${lang}/berita/${post.slug.ms}`">{{ `https://jommenyumbang.com/${lang}/berita/${post.slug[lang]}` }} <i class="fa fa-external-link"></i></a></span>

                    <div v-if="initPost && initPost.id" class="form-group">
                        <div class="form-label">Publish Date <span class="text-red">*</span></div>
                        <input type="date" class="form-control" name="published_at" id="published_at" v-model="post.published_at"></input>
                    </div>

                    <div class="form-group">
                        <div class="form-label">Content <span class="text-red">*</span></div>
                        <editor
                            api-key="ld33zdqw1j584sq2xzacd6z5uk91buqq8fgcbo99fscraepd"
                            :name="`content[${lang}]`"
                            id="content"
                            :init="{
                                height: 600,
                                plugins: [
                                'emoticons',
                                'link'
                                ],
                            }"
                            v-model="post.content[lang]"
                            required="true"
                        />
                        <span v-if="errors[`content.${lang}`] && errors[`content.${lang}`][0]" class="text-danger text-sm">{{ errors[`content.${lang}`][0] }}</span>
                        <p v-if="lang != 'ms'" class="text-sm text-muted pl-3 pt-1" v-html="post.content.ms"></p>
                    </div>

                    <div class="form-group">
                        <div class="form-label">Post Image <span class="text-red">*</span></div>
                        <div class="d-flex align-items-center mb-2">
                            <div class="custom-file" style="width:30%" v-if="lang == 'ms'">
                                <input id="image_ms" class="custom-file-input" type="file" name="image_ms" ref="image_ms" @change="handleFileUpload()">
                                <label class="custom-file-label" for="image_ms">Choose file</label>
                            </div>
                            <div class="custom-file" style="width:30%" v-if="lang == 'en'">
                                <input id="image_en" class="custom-file-input" type="file" name="image_en" ref="image_en" @change="handleFileUpload()">
                                <label class="custom-file-label" for="image_en">Choose file</label>
                            </div>
                            <p class="mb-0 ml-2">{{ images[lang] ? images[lang].name : '' }}</p>
                        </div>
                        <div v-if="mode == 'edit' && getPostImageUrl()">
                            <img :src="getPostImageUrl()" class="img-thumbnail mb-2" style="height: 200px; width: auto;">
                        </div>
                        <div v-if="post.imageUrls.images && post.imageUrls['images'] && !post.imageUrls[`images-${lang}`]">
                            <img :src="post.imageUrls.images" class="img-thumbnail mb-2" style="height: 200px; width: auto;">
                            <p class="text-danger">
                                Default poster is already available. Translation poster is required
                            </p>
                        </div>
                    </div>

                    <div class="d-flex justify-content-start">
                        <button v-if="mode == 'add'" type="button" id="add-post" @click="submit()" class="btn btn-primary" :disabled="!getFormValidity() || isUpdating">Add Post</button>
                        <button v-else type="button" id="update-post" @click="submit()" class="btn btn-primary" :disabled="!getFormValidity() || isUpdating">Update Post</button>
                        <moon-loader class="ml-2" :loading="isUpdating" id="spinner" :color="'#26A65B'" :size="'36px'"></moon-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
    .post-language-button.content-valid::after {
        background-color: #1d9f3c;
    }
    .post-language-button:not(.content-valid) {
        color: #cd201f !important;
    }
    .post-language-button {
        padding-left: 1.5rem !important;
    }
    .post-language-button::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        height: 7px;
        width: 7px;
        background-color: #cd201f;
        left: 1px;
        top: 55%;
        margin-top: -4px;
    }
    .post-toast {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
        color: white;
        background-color: #5eba00 !important;
    }
    .dont-break-out {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
</style>

<script>
    import { MoonLoader } from 'vue-spinner/dist/vue-spinner.min';
    import Editor from '@tinymce/tinymce-vue';

    export default {
        data() {
            return {
                lang: this.initLang,
                post: {
                    title: {
                        'en': '',
                        'ms': ''
                    },
                    slug: {
                        'en': '',
                        'ms': ''
                    },
                    content: {
                        'en': '',
                        'ms': ''
                    },
                    imageUrls: [],
                    published_at: '',
                },
                images: {
                    ms: '',
                    en: ''
                },
                errors: {},
                isUpdating: false
            };
        },
        components: {
            MoonLoader,
            'editor': Editor
        },
        props: {
            routeBack: {},
            route: {},
            csrf: {},
            trans: {},
            initLang: {},
            mode: {},
            initPost: {
                default: function() {
                    return { id: null };
                },
            },
        },
        computed: {
        },
        methods: {
            handleFileUpload() {
                this.images[this.lang] = this.$refs[`image_${this.lang}`].files[0];
            },
            submit() {
                this.isUpdating = true;
                let data = new FormData();

                if (this.images['ms']) {
                    data.append('image_ms', this.images['ms']);
                }

                if (this.images['en']) {
                    data.append('image_en', this.images['en']);
                }

                if (this.mode == 'edit') {
                    data.append('_method', 'PUT');
                }
                
                Object.keys(this.post).forEach(key => {
                    let input = 
                        ['title', 'slug', 'content', 'media'].includes(key)
                            ? JSON.stringify(this.post[key])
                            : this.post[key];

                    data.append(key, input);

                    if (key == 'published_at' && this.post.published_at == '')
                        data.append(key, null);
                });

                axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                axios.post(
                    this.route,
                    data
                ).then((response) => {
                    this.$toasted.show(`Post ${this.mode == 'add' ? 'added' : 'updated'}`, { 
                        theme: "toasted-primary", 
                        position: "bottom-left", 
                        duration : 20000,
                        icon: {name: 'check'},
                        className: ['post-toast', 'font-weight-bold']
                    });

                    this.resetData(response.data);

                    if (this.mode == 'add') {
                        window.location.href = this.routeBack;
                    } else {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }
                    this.isUpdating = false;
                }).catch((error) => {
                    console.log(error);
                    console.log(error.response);
                    window.scrollTo(0,0);
                    this.errors = _.get(error, 'response.data.errors');
                    this.isUpdating = false;
                });
            },
            getPostImageUrl() {
                return this.post.imageUrls[`images-${this.lang}`];
            },
            resetData(post) {
                this.post = post;
                if (post.published_at) {
                    this.post.published_at = moment(post.published_at).format('YYYY-MM-DD');
                }
                this.images = {
                    ms: '',
                    en: ''
                };
                this.errors = {};
            },
            getContentValidity(lang) {
                let valid = this.post.title[lang] &&
                        this.post.content[lang] &&
                        (this.post.imageUrls[`images-${lang}`] || this.images[lang]);

                return valid;
            },
            getFormValidity() {
                return this.getContentValidity('ms') && this.getContentValidity('en');
            }
        },
        mounted() {
            if (this.initPost && this.initPost.id) {
                this.post = this.initPost;
                if (this.initPost.published_at) {
                    this.post.published_at = moment(this.initPost.published_at).format('YYYY-MM-DD');
                }
            }
        }
    }
</script>