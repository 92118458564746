<template>
    <div class="d-flex flex-column flex-grow-1">
        <div class="mb-4 d-flex">
            <div class="custom-form-selectgroup d-flex flex-column text-left flex-grow-1 m-0">
                <div class="row d-none d-md-flex mx-0 mb-3">
                    <div class="col-5 px-0 d-flex align-items-center justify-content-start">
                        <div class="form-label">{{ trans['Pilih Pakej'] }}</div>
                    </div>
                    <div class="col-2 pl-0 pr-9 d-flex align-items-center justify-content-end">
                        <div class="form-label">{{ trans['RM/unit'] }}</div>
                    </div>
                    <div class="col-3 px-0 d-flex align-items-center justify-content-start">
                        <div class="form-label">{{ trans['Bilangan'] }}</div>
                    </div>
                    <div class="col-2 px-0 d-flex align-items-center justify-content-end">
                        <div class="form-label">{{ trans['Jumlah'] }}</div>
                    </div>
                </div>
                <div class="row mx-0 mb-3" v-for="(campaignPackage, index) in packages">
                    <div class="col-6 col-md-5 pl-0 pr-3 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start">
                        <span class="text-md font-weight-bold">{{ campaignPackage.name[lang] }}</span>
                        <input type="hidden" name="packageIds[]" :value="campaignPackage.id">
                        <div class="d-block d-md-none" v-if="campaignPackage.is_open == 0 && campaignPackage.amount">
                            RM{{ parseInt(campaignPackage.amount) }}
                        </div>
                    </div>
                    <div class="d-none d-md-flex col-md-2 pl-md-0 pr-md-9 align-items-center justify-content-end">
                        <span v-if="campaignPackage.is_open == 0 && campaignPackage.amount">
                            RM{{ parseInt(campaignPackage.amount) }}
                        </span>
                    </div>
                    <div class="col-6 col-md-3 px-0 d-flex align-items-center justify-content-start">
                        <counter
                            v-if="!isPackageOpenPrice(campaignPackage)"
                            @change-counter="handleChangeCounter($event, index)"
                            name="quantities[]"
                            :init-counter="quantity"
                        >
                        </counter>
                        <currency-input
                            v-if="isPackageOpenPrice(campaignPackage)"
                            @change-currency-input="handleChangeCurrencyInput($event, index)"
                            name="currencyInputs[]"
                            v-model="openPriceAmounts[index]"
                        >
                        </currency-input>
                        <input v-if="isPackageOpenPrice(campaignPackage)" type="hidden" name="quantities[]" value="-1">
                    </div>
                    <div class="d-none d-md-flex col-md-2 px-md-0 align-items-center justify-content-end">
                        <span>{{ getTotal(getPackage(campaignPackage.id)).toFixed(2) }}</span>
                        <input type="hidden" name="amounts[]" :value="getTotal(getPackage(campaignPackage.id)).toFixed(2)">
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex font-weight-bold justify-content-end">
            <div class="d-flex flex-column flex-md-row">
                <div class="">
                    {{ trans['Jumlah'] }} (RM)
                </div>
                <div class="ml-md-5 text-right">
                    <span class="">{{ totalAmount }}</span>
                    <input type="hidden" name="total_amount" v-model="totalAmount">
                </div>
            </div>
        </div>

        <div v-if="korbanNames.length && isNameRequired" class="flex-grow-1 mt-3">
            <div class="form-label">{{ trans['Nama Penama Korban'] }}</div>
            <div class="row">
                <div class="col-7">
                    <div v-for="korbanName in korbanNames" class="form-group">
                        <input type="text" class="form-control" name="korban_name[]" style="text-transform: uppercase;">
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3">
            <button type="submit" class="btn btn-primary" :disabled="totalAmount < 3">{{ trans['DERMA']}}</button>
        </div>
    </div>
</template>

<style>
.custom-form-selectgroup {
    display: inline-flex;
    margin: 0 -.5rem -.5rem 0;
    flex-wrap: wrap;
}
.custom-form-selectgroup .custom-form-selectgroup-item {
    margin: 0 .5rem .5rem 0;
}
.custom-form-selectgroup-item {
    display: block;
    position: relative;
}
.custom-form-selectgroup-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}
</style>

<script>
    export default {
        data() {
            return {
                selectedPackage: '',
                openPriceAmounts: [],
                packages: [],
                korbanNames: [],
                quantity: 0
            };
        },
        props: [
            'initCampaign',
            'lang',
            'trans',
            'kafalahId'
        ],
        computed: {
            totalAmount() {
                return _.sumBy(this.packages, (p) => {
                    return this.getTotal(p);
                }).toFixed(2);
            },
            isNameRequired() {
                return this.initCampaign.preferences && this.initCampaign.preferences.is_name_required && this.initCampaign.preferences.is_name_required === true;
            }
        },
        methods: {
            getTotal(campaignPackage) {
                if (this.isPackageOpenPrice(campaignPackage)) {
                    if (!campaignPackage.amount){
                        return 0;
                    }
                    return parseInt(campaignPackage.amount);
                }

                return (parseInt(campaignPackage.amount) * campaignPackage.quantity);
            },
            handleChangeCounter(count, index) {
                Vue.set(this.packages[index], 'quantity', count)

                if (count > 0) {
                    this.korbanNames = []
                    for (var i = 0; i < count; i++) {
                        this.korbanNames.push({});
                    }
                }
            },
            handleChangeCurrencyInput(currecyInput, index) {
                Vue.set(this.packages[index], 'amount', currecyInput)
            },
            getPackage(packageId) {
                return _.find(this.packages, (p) => {
                    return p.id == packageId;
                });
            },
            isPackageOpenPrice(campaignPackage) {
                return this.getPackage(campaignPackage.id) && this.getPackage(campaignPackage.id).is_open == 1;
            },
            isKafalahCampaign() {
                return this.initCampaign.id == this.kafalahId;
            }
        },
        mounted() {
            this.packages = this.initCampaign.packages
                .filter(p => {
                    if (this.isKafalahCampaign()) {
                        return p.is_open;
                    } else {
                        return true;
                    }
                })
                .map(p => {
                    p.quantity = 0;
                    return p;
                });

            for (var i = 0; i < this.packages.length; i++) {
                this.openPriceAmounts.push(0);
            }
        }
    }
</script>