<template>
    <div class="row">
        <div class="col">
            <div class="card mb-0">
                <div class="datepicker" :class="className" ref="container">
                    <input type="hidden" name="startDate" :value="startDateInput">
                    <input type="hidden" name="endDate" :value="endDateInput">
                    <div class="btn-group summary-group">
                        <button type="button" class="btn white prev" @click="previousRange()"></button>
                        <div class="summary">
                            <button type="button" class="btn white" @click="popup()">{{ rangeText }}</button>
                        </div>
                        <button type="button" class="btn white next" @click="nextRange()"></button>
                    </div>
                    <div class="datepicker-popup" v-if="showPopup">
                        <div class="datepicker-inner">
                            <div class="datepicker-body">
                                <div class="datepicker-ctrl">
                                    <span class="datepicker-preBtn fe fe-arrow-left" aria-hidden="true"
                                    @click="previous()" v-show="canGoPrevious"></span>
                                    <span class="datepicker-nextBtn fe fe-arrow-right" aria-hidden="true"
                                    @click="next()" v-show="canGoNext"></span>
                                    <p @click="selectMonth(currentDate)">{{ currentDate.format('MMMM YYYY') }}</p>
                                </div>
                                <div class="datepicker-weekRange">
                                    <span v-for="w in daysOfWeek">{{ w }}</span>
                                </div>
                                <div class="datepicker-dateRange">
                                    <span v-for="d in dateRange" :class="d.sclass" @click="selectDate(d.date)">{{ d.text }}</span>
                                </div>
                                <div class="datepicker-rangeInput">
                                    <div class="datepicker-startInput">
                                        <small class="text-muted">Start</small>
                                        <input class="form-control"
                                        type="text"
                                        :value="startDateText"
                                        @blur="setStartDate($event.target.value)"
                                        @keyup.enter="setStartDate($event.target.value)"/>
                                    </div>
                                    <div class="datepicker-endInput">
                                        <small class="text-muted">End</small>
                                        <input class="form-control"
                                        type="text"
                                        :value="endDateText"
                                        @blur="setEndDate($event.target.value)"
                                        @keyup.enter="setEndDate($event.target.value)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="datepicker-shortcuts">
                                <div class="btn-group-vertical w-100"> 
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectAllTime()">All time</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectToday()">Today</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectYesterday()">Yesterday</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectThisWeek()">This week</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectLastWeek()">Last week</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectThisMonth()">This month</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectMonthToDate()">Month to Date</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectLastMonth()">Last month</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectThisYear()">This Year</button>
                                    <button type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="selectYearToDate()">Year to Date</button>
                                    <button v-if="allowEmpty" type="button" class="btn btn-secondary btn-block white mb-2 button-filter" @click="clearDate()">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Moment from 'moment';
    import { extendMoment } from 'moment-range';
    const moment = extendMoment(Moment);
    import twix from 'twix';

    export default {
        props: {
            format: {
                type: String,
                default: 'YYYY-MM-DD',
            },
            min: {},
            max: {},
            start: {},
            end: {},
            useVuex: {
                type: Boolean,
                default: true
            },
            className: {
                type: String,
                default: ''
            },
            autoInitDate: {
                type: Boolean,
                default: true
            },
            allowEmpty: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                currentDate: moment(),
                startDate: moment('2014-01-01').startOf('day'),
                endDate: moment().endOf('day'),
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                dateRange: [],
                hasStart: true,
                hasEnd: true,
                showPopup: false,
            };
        },

        mounted() {
            document.addEventListener('click', this.handleClickOutside, true);
            this.setStartAndEndDate();
        },

        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside, true);
        },

        watch: {
            currentDate() {
                this.generateDateRange();
            },

            startDate() {
                this.generateDateRange();
            },

            endDate() {
                this.generateDateRange();
            },
        },

        computed: {
            canGoPrevious() {
                let newDate = moment(this.currentDate).subtract(1, 'month');
                return newDate.isSameOrAfter(this.getMinimumDate().startOf('month'));
            },

            canGoNext() {
                let newDate = moment(this.currentDate).add(1, 'month');
                return newDate.isSameOrBefore(this.getMaximumDate().endOf('month'));
            },

            startDateText() {
                return this.getStartDate().format('YYYY-MM-DD hh:mm A');
            },

            endDateText() {
                return this.getEndDate().format('YYYY-MM-DD hh:mm A');
            },

            startDateInput() {
                return this.getStartDate().format('YYYY-MM-DD');
            },

            endDateInput() {
                return this.getEndDate().format('YYYY-MM-DD');
            },

            rangeText() {
                if (this.dateSetAsAllTime()) {
                    return 'All time';
                }

                if (this.noDateDefine()) {
                    return 'Pick a date';
                }

                if (this.dateSetAsThisYear()) {
                    return 'This year';
                }

                return moment(this.getStartDate())
                .twix(this.getEndDate(), {
                    allDay: true,
                })
                .format();
            },
        },

        methods: {
            popup() {
                if (this.showPopup) {
                    this.closePopup();
                } else {
                    this.openPopup();
                }
            },

            openPopup() {
                this.showPopup = true;
                this.generateDateRange();
            },

            closePopup() {
                this.showPopup = false;
            },

            handleClickOutside(e) {
                const el = this.$refs.container;

                if (!el.contains(e.target)) {
                    if (this.showPopup) {
                        this.closePopup();
                    }
                }
            },

            generateDateRange() {
                this.dateRange = [];

                var that = this;

                var start = moment(this.currentDate)
                .startOf('month')
                .startOf('week');

                var end = moment(this.currentDate)
                .endOf('month')
                .endOf('week');

                if (this.extraPreviousWeekEnabled()) {
                    start.subtract(1, 'week');
                }

                if (!this.extraPreviousWeekEnabled() && this.extraNextWeekEnabled()) {
                    end.add(1, 'week');
                }

                var range = moment.range(start, end);

                for (let day of range.by('days')) {
                    this.dateRange.push({
                        date: day.startOf('day'),
                        text: day.format('D'),
                        sclass: this.getDateClass(day),
                    });
                }
            },

            clampDate(date) {
                if (date == null) {
                    return;
                }

                if (date.isAfter(this.getMaximumDate())) {
                    return this.getMaximumDate();
                }

                if (date.isBefore(this.getMinimumDate())) {
                    return this.getMinimumDate();
                }

                return date;
            },

            selectDate(date) {
                if (this.getMinimumDate().isAfter(date) || this.getMaximumDate().isBefore(date)) {
                    return;
                }

                if (this.hasStart && !this.hasEnd) {
                    if (date.isBefore(this.startDate)) {
                        var temp = moment(this.startDate);
                        this.selectRange(date, temp);
                        this.hasEnd = true;
                    } else {
                        this.selectRange(this.startDate, date);
                        this.hasEnd = true;
                    }

                    return;
                }

                if (this.hasStart && this.hasEnd) {
                    this.selectRange(date, date);
                    this.hasEnd = false;
                }
            },

            setStartDate(date, time = true) {
                var finalDate = this.parseDate(date, null);

                if (finalDate != null && time) {
                    finalDate = finalDate.startOf('day');

                }

                this.startDate = this.clampDate(finalDate);
            },

            setEndDate(date, time = true) {
                var finalDate = this.parseDate(date, null);

                if (finalDate != null && time) {
                    finalDate = finalDate.endOf('day');
                }

                // this.endDate = this.clampDate(finalDate);
                this.endDate = finalDate;
            },

            parseDate(date, def = moment()) {
                if (moment.isMoment(date)) {
                    return moment(date);
                }

                let parsed = moment(date, this.format);

                if (parsed.isValid()) {
                    return parsed;
                }

                return def;
            },

            navigateToDate(date) {
                if (!date)
                    return;

                let month = date.month();
                let year = date.year();

                this.currentDate = moment(this.currentDate)
                .month(month)
                .year(year);
            },

            selectRange(start, end) {
                this.setStartDate(start);
                this.setEndDate(end);
                this.navigateToDate(this.clampDate(start));

                let payload = {
                    start: this.startDate ? this.startDate.format() : null,
                    end: this.endDate ? this.endDate.format() : null
                };

                this.$emit('onChange', payload);

                if (this.useVuex) {
                    this.$store.commit('setReportRange', {
                        start: this.startDate,
                        end: this.endDate,
                    });
                }
            },

            clearDate() {
                this.selectRange(null, null);
                this.closePopup();
            },

            selectAllTime() {
                var start = moment('2014-01-01').startOf('day');
                var end = moment().endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectToday() {
                var start = moment().startOf('day');
                var end = moment().endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectYesterday() {
                var start = moment().subtract(1, 'day').startOf('day');
                var end = moment().subtract(1, 'day').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectThisWeek() {
                var start = moment().startOf('week').startOf('day');
                var end = moment().endOf('week').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectLastWeek() {
                var start = moment().startOf('week').subtract(1, 'week').startOf('day');
                var end = moment().endOf('week').subtract(1, 'week').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectThisMonth() {
                var start = moment().startOf('month').startOf('day');
                var end = moment().endOf('month').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectMonthToDate() {
                var start = moment().startOf('month').startOf('day');
                var end = this.clampDate(moment().endOf('month').endOf('day'));
                this.selectRange(start, end);
                this.closePopup();
            },

            selectThisYear() {
                var start = moment().startOf('year').startOf('day');
                var end = moment().endOf('year').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectYearToDate() {
                var start = moment().startOf('year').startOf('day');
                var end = this.clampDate(moment().endOf('year').endOf('day'));
                this.selectRange(start, end);
                this.closePopup();
            },

            selectLastMonth() {
                var start = moment().startOf('month').subtract(1, 'month').startOf('day');
                var end = moment().endOf('month').subtract(1, 'month').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            selectMonth(date) {
                var start = moment(this.currentDate).startOf('month').startOf('day');
                var end = moment(this.currentDate).endOf('month').endOf('day');
                this.selectRange(start, end);
                this.closePopup();
            },

            setStartAndEndDate() {
                if (this.start == null && this.end == null) {
                    if (this.autoInitDate) {
                        this.selectToday();
                    } else {
                        this.clearDate();
                    }

                    return;
                }

                if (this.start != null) {
                    this.startDate = moment(this.start, this.format);
                }

                if (this.end != null) {
                    this.endDate = moment(this.end, this.format);
                }
            },

            extraPreviousWeekEnabled() {
                return moment(this.currentDate)
                .startOf('month')
                .endOf('week')
                .subtract(4, 'day')
                .isSame(this.currentDate, 'month');
            },

            extraNextWeekEnabled() {
                return moment(this.currentDate)
                .endOf('month')
                .startOf('week')
                .add(4, 'day')
                .isSame(this.currentDate, 'month');
            },

            getDateClass(date) {
                var clazz = [];

                if (!this.currentDate.isSame(date, 'month')) {
                    clazz.push('datepicker-day-different-month');
                }

                if (moment().isSame(date, 'day')) {
                    clazz.push('datepicker-day-today');
                }

                if (date.isBetween(this.getStartDate(), this.getEndDate(), 'day', '[]')) {
                    clazz.push('datepicker-day-selected')
                }

                if (this.getMinimumDate().isAfter(date) || this.getMaximumDate().isBefore(date)) {
                    clazz.push('datepicker-day-disabled')
                }

                return clazz.join(' ');
            },

            getStartDate() {
                if (this.startDate == null) {
                    return moment().startOf('day');
                }

                return this.startDate;
            },

            getEndDate() {
                if (this.endDate == null) {
                    if (this.startDate != null) {
                        return this.getStartDate().endOf('day');
                    } else {
                        return moment().endOf('day');
                    }
                }

                return this.endDate;
            },

            getMinimumDate() {
                if (this.min != null) {
                    return moment(this.min, this.format);
                }

                return moment('2014-01-01');
            },

            getMaximumDate() {
                if (this.max != null) {
                    return moment(this.max, this.format);
                }

                return moment().endOf('day');
            },

            rangeIsExactlyOneMonth() {
                let lastDay = moment(this.getStartDate()).endOf('month').date();
                let isFirstDay = moment(this.getStartDate()).date() == 1;
                let isLastDay = moment(this.getEndDate()).date() == lastDay;

                return isFirstDay && isLastDay;
            },

            previous() {
                if (this.canGoPrevious) {
                    this.currentDate = moment(this.currentDate).subtract(1, 'month');
                }
            },

            next() {
                if (this.canGoNext) {
                    this.currentDate = moment(this.currentDate).add(1, 'month');
                }
            },

            previousRange() {
                let start;
                let end;

                if (this.rangeIsExactlyOneMonth()) {
                    start = this.getStartDate()
                    .subtract(1, 'month')
                    .startOf('month');

                    end = this.getEndDate()
                    .subtract(1, 'month')
                    .endOf('month');
                } else {
                    let range = moment.range(this.getStartDate(), this.getEndDate())
                    .diff('days') + 1;

                    start = moment(this.getStartDate())
                    .subtract(range, 'day');

                    end = moment(this.getEndDate())
                    .subtract(range, 'day');
                }

                this.selectRange(start, end);
            },

            nextRange() {
                let start;
                let end;

                if (this.rangeIsExactlyOneMonth()) {
                    start = this.getStartDate()
                    .add(1, 'month')
                    .startOf('month');

                    end = this.getEndDate()
                    .add(1, 'month')
                    .endOf('month');
                } else {
                    let range = moment.range(this.getStartDate(), this.getEndDate())
                    .diff('days') + 1;

                    start = moment(this.getStartDate())
                    .add(range, 'day');

                    end = moment(this.getEndDate())
                    .add(range, 'day');
                }

                this.selectRange(start, end);
            },

            noDateDefine() {
                return this.startDate == null && this.endDate == null;
            },

            dateSetAsAllTime() {
                return this.startDateInput == '2014-01-01' && this.endDateInput == moment().format('YYYY-MM-DD');
            },

            dateSetAsThisYear() {
                return this.startDateInput == moment().startOf('year').format('YYYY-MM-DD') && this.endDateInput == moment().endOf('year').format('YYYY-MM-DD'); 
            }
        },
    }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "../../sass/mixins";

$border-color: #CCC;

.body {
    display: inline-block;
    width: 28px;
    line-height: 28px;
    height: 28px;
    border-radius: 4px;
    @include user-select(none);
}

.datepicker {
    position: relative;
}

.datepicker-inner {
    width: 350px;

    > div {
        float: left;
    }
}

.datepicker-body {
    width: 218px;
    padding: 10px 10px;
    display: inline-block;

    span {
        @extend .body;
        text-align: center;
    }
}

.datepicker-shortcuts {
    width: 132px;
    padding: 10px 10px;
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid $border-color;
}

.button-filter {
    font-size: 0.7rem !important;
}

.datepicker-dateRange {
    span {
        cursor: pointer;

        &:hover {
            background-color: #eeeeee;
        }
    }
}

.datepicker-weekRange {
    span {
        font-weight: bold;
    }
}

.datepicker-day-disabled {
    color: #ddd !important;
    cursor: not-allowed !important;

    &:hover {
        background-color: transparent !important;
    }
}

.datepicker-day-different-month {
    color: #999;
}

.datepicker-day-today {
    font-weight: bold;
}

.datepicker-day-selected {
    background-color: #0cc2aa;
    border-radius: 0 !important;
    color: #fff;

    &:hover {
        background-color: #0cc2aa !important;
    }
}

.datepicker-ctrl {
    position: relative;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;

    p {
        @extend .body;
        width: 65%;
        cursor: pointer;

        &:hover {
            background-color: #eeeeee;
        }
    }

    span {
        @extend .body;
        position: absolute;
        cursor: pointer;
    }
}

.datepicker-popup {
    position: absolute;
    right: 0;
    border: 1px solid $border-color;
    border-radius: 5px;
    background: #fff;
    margin-top: 2px;
    z-index: 1000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.datepicker-rangeInput {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $border-color;

    div + div {
        margin-top: 10px;
    }

    input {
        font-size: 14px;
    }
}

.datepicker-preBtn,
.datepicker-nextBtn {
    &:hover {
        background-color: #eeeeee;
    }
}

.datepicker-preBtn {
    left: 2px;
}

.datepicker-nextBtn {
    right: 2px;
}

.summary-group {
    width: 100%;
    display: flex;

    .chevron {
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: .25rem;
        margin-left: .25rem;
        vertical-align: middle;
        content: "";
    }

    .prev {
        float: left;
        margin-right: -1px;

        &::after {
            @extend .chevron;
            border-top: .3em solid transparent;
            border-right: .3em solid;
            border-bottom: .3em solid transparent;
        }
    }

    .summary {
        overflow: hidden;
        flex-grow: 1;
        margin-left: -1px;
        margin-right: -1px;

        .btn {
            width: 100%;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;

            &::after {
                @extend .chevron;
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-left: .3em solid transparent;
            }
        }
    }

    .next {
        float: right;

        &::after {
            @extend .chevron;
            border-top: .3em solid transparent;
            border-left: .3em solid;
            border-bottom: .3em solid transparent;
        }
    }
}
</style>
