<template>
    <div>
        <div class="row py-2 px-2">
            <div class="col-8">
                <input type="range" name="month" class="form-control custom-range" step="1" min="0" :max="undistributedBills.length" v-model="month">
            </div>
            <div class="col-2 px-0">
                <span class="text-sm">{{month}} {{month > 1 ? 'months' : 'month'}}/RM{{ monthlyAmount * month }}</span>
            </div>
            <div class="col-2">
                <button class="btn btn-secondary btn-sm" :disabled="month == 0">Distribute</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                month: 0,
            };
        },
        props: [
            'monthlyAmount',
            'undistributedBills'
        ],
        computed: {

        },
        methods: {

        },
        mounted() {

        }
    }
</script>