window.toastr = require('toastr');

toastr.options = {
    'closeButton': false,
    'debug': false,
    'positionClass': 'toast-bottom-left',
    'showMethod': 'fadeIn',
    "hideMethod": "fadeOut",
    'showDuration': '300',
    'hideDuration': '100',
    'timeOut': '2000',
}
