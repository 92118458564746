<template>
    <div class="form-group">
        <div class="row">
            <div class="col-2">
                <div class="form-label">Type</div>
            </div>
            <div class="col-3">
                <div class="form-label">Name (Malay) <span class="text-red">*</span></div>
            </div>
            <div class="col-3">
                <div class="form-label">Name (English) <span class="text-red">*</span></div>
            </div>
            <div class="col-2">
                <div class="form-label">Amount/Quantity (RM/unit) <span class="text-red">*</span></div>
            </div>
        </div>
        <div v-for="(campaignPackage, index) in packages.filter(p => !p.archived_at)" class="row mb-4">
            <div class="col-2">
                <select v-model="campaignPackage.is_open" class="custom-select" :disabled="campaignPackage.id">
                    <option value="0">Fixed Amount</option>
                    <option value="1">Open Donation</option>
                </select>
            </div>
            <div class="col-3">
                <input type="text" class="form-control" name="package_name_ms" v-model="campaignPackage.name['ms']" placeholder="1 Ekor Lembu (Palestin & Syria)" @input="handleChange($event)">
            </div>
            <div class="col-3">
                <input type="text" class="form-control" name="package_name_en" v-model="campaignPackage.name['en']" placeholder="1 Head of Cattle (Palestine & Syria)" @input="handleChange($event)">
            </div>
            <div class="col-2">
                <input v-if="campaignPackage.is_open == 0" type="number" class="form-control" name="package_amount" v-model="campaignPackage.amount" :required="!campaignPackage.is_open" @input="handleChange($event)" :disabled="campaignPackage.is_open == 1">
            </div>
            <div class="col-2" v-if="campaignPackage.id">
                <button class="btn btn-secondary text-muted" @click="archivePackage(campaignPackage.id)"><i class="fe fe-inbox"></i> Archive</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-start">
                <button type="button" @click="addPackage()" class="btn btn-secondary mr-2">Add Package</button>
                <button type="button" v-if="packages.filter(p => !p.archived_at).length > 1" @click="removePackage()" class="btn btn-secondary">Remove Package</button>
            </div>
            <div v-if="errors['packages'] && errors['packages'][0]" class="col-12 mt-2 text-danger text-sm">{{ errors['packages'][0] }}</div>
        </div>

        <h5 v-if="packages.filter(p => p.archived_at).length" class="mt-6 text-muted">Archived Package(s)</h5>
        <div v-for="(campaignPackage, index) in packages.filter(p => p.archived_at)" class="row mb-4">
            <div class="col-2">
                <select v-model="campaignPackage.is_open" class="custom-select" disabled>
                    <option value="0">Fixed Amount</option>
                    <option value="1">Open Donation</option>
                </select>
            </div>
            <div class="col-3">
                <input type="text" class="form-control" v-model="campaignPackage.name['ms']" disabled>
            </div>
            <div class="col-3">
                <input type="text" class="form-control" v-model="campaignPackage.name['en']" disabled>
            </div>
            <div class="col-2">
                <input v-if="campaignPackage.is_open == 0" type="number" class="form-control" v-model="campaignPackage.amount" :required="!campaignPackage.is_open" @input="handleChange($event)" disabled>
            </div>
            <div class="col-2" v-if="campaignPackage.id">
                <button class="btn btn-dark text-white" @click="unarchivePackage(campaignPackage.id)"><i class="fe fe-inbox"></i> Unarchive</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                packages: _.get(this.initPackages[0], 'name') ? this.initPackages : [{
                    name: {
                        'en': '',
                        'ms': ''
                    },
                    amount: '',
                    archived_at: '',
                    is_open: 0
                }]
            };
        },
        props: {
            lang: {
                type: String,
                default: ''
            },
            initPackages: {
                type: Array,
                default: function() { return [{}] }
            },
            modSumbangan: {
                type: String,
                default: 'pakej_tetap'
            },
            oldRequest: {
                type: [Object, Array],
                default: function() { return {} }
            },
            errors: {
                type: Object,
                default: function() { return {} }
            }
        },
        computed: {
        },
        methods: {
            addPackage() {
                this.packages.push({
                    name: {
                        'en': '',
                        'ms': ''
                    },
                    amount: '',
                    archived_at: '',
                    is_open: 0
                });
            },
            removePackage() {
                this.packages.pop();
            },
            handleChange(event) {
                let data = {
                    lang: this.lang,
                    packages: this.packages
                };
                this.$emit('packagesContentChange', data);
            },
            archivePackage(packageId) {
                let pkgIndex = this.packages.findIndex(p => p.id == packageId);
                (this.packages)[pkgIndex].archived_at = moment().format('YYYY-MM-DD HH:mm:ss');
                let data = {
                    lang: this.lang,
                    packages: this.packages
                };
                this.$emit('packagesContentChange', data);
            },
            unarchivePackage(packageId) {
                let pkgIndex = this.packages.findIndex(p => p.id == packageId);
                (this.packages)[pkgIndex].archived_at = '';
                let data = {
                    lang: this.lang,
                    packages: this.packages
                };
                this.$emit('packagesContentChange', data);
            }
        },
        mounted() {
        }
    }
</script>