<template>
    <div>
        <div class="card mt-4">

            <!-- check_donor_status_card -->
            <div class="card-body" id="check_donor_status_card">
                <div class="row">
                    <div class="col-3">
                        <h4>Donor</h4>
                    </div>
                    <div class="col-9">
                        <div class="form-group">
                            <label class="form-label">
                                <input type="radio" name="donor_status" id="donor_status" value="new" class="mr-2" v-model="donorMode">
                                <span class="">New</span>
                            </label>
                            <label class="form-label">
                                <input type="radio" name="donor_status" id="donor_status" value="find" class="mr-2" v-model="donorMode">
                                <span class="">Existing</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <donor-search
                v-if="donorMode == 'find'"
                :init-donor="donor"
                @changeDonor="handleChangeDonor($event)"
            >
            </donor-search>

            <!-- form_donor_card -->
            <div v-if="donorMode == 'new'" class="card-body" id="form_donor_card">
                <div class="row">
                    <div class="col-3">
                        <h4>Donor Details</h4>
                    </div>

                    <div class="col-9">
                        <div class="row">
                            <div class="col-3">
                                <form-select label="Presalutation" name="presalutation_id" :items="presalutations"></form-select>
                            </div>

                            <div class="col-3">
                                <form-select label="Salutation" name="salutation_id" :items="salutations"></form-select>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-label">Full Name <span style="color:red;font-weight:bold">*</span></div>
                            <input type="text" class="form-control" style="text-transform: uppercase;" name="fullname" id="fullname" placeholder="" value="" required>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group">
                                    <div class="form-label">Username <span class="text-muted">(Optional)</span></div>
                                    <input type="text" class="form-control" name="username" id="username" placeholder="" value="">
                                </div>
                            </div>
                            <div class="col-3">
                                <form-select label="Marital Status" name="marital_id" :items="maritals"></form-select>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="form-label pb-2">Gender</div>
                                    <div class="custom-controls-stacked">
                                        <label v-for="gender in genders" class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input" name="gender_id" :value="gender.id" v-model="selectedGender">
                                            <span id="`gender_label_${gender.id}`" class="custom-control-label"> {{ gender.name }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6 pl-0">
                            <div class="form-label">{{ selectedGender == 3 ? 'Company Registration No.' : 'Identification Card No.' }}</div>
                            <input type="text" class="form-control" name="nric" id="nric" placeholder="" value="" onBlur="javascript:{this.value = this.value.replace(/[\s)(+-]/g, ''); }">
                        </div>
                        <div class="form-group">
                            <div class="form-label">Address</div>
                            <input type="text" class="form-control" name="address" id="address" placeholder="" value="">
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="form-label">Postcode</div>
                                    <input type="text" class="form-control" name="postcode" id="postcode" placeholder="" value="">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">City</div>
                                    <input type="text" class="form-control" name="city" id="city" placeholder="" value="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <form-select label="State" name="state_id" :items="states"></form-select>
                            </div>
                            <div class="col-6">
                                <form-select label="Country" name="country_id" :items="countries" :default-item-id="malaysiaId"></form-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">Home Phone No.</div>
                                    <input type="text" class="form-control" name="phone_home" id="phone_home" placeholder="" value="" onBlur="javascript:{this.value = this.value.replace(/[\s)(+-]/g, ''); }">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">Mobile Phone No. <span style="color:red;font-weight:bold">*</span></div>
                                    <input type="text" class="form-control" name="phone_mobile" id="phone_mobile" placeholder="" value="" onBlur="javascript:{this.value = this.value.replace(/[\s)(+-]/g, ''); }" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-label">Email</div>
                            <input type="text" class="form-control" name="email" id="email" placeholder="" value="" style="width: 50%;">
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <form-select label="Branch" name="branch_id" :items="branches" :required="true" :default-item-id="selectedBranch" ></form-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- list_bank_card -->
            <div v-if="donorMode == 'find' && this.donor.id && this.donor.active_recurrings" class="card-body" id="list_bank_card">
                <div class="row">
                    <div class="col-3">
                        <h4>List of Subscriptions</h4>
                    </div>
                    <div class="col-9">
                        <div v-for="recurring in donor.active_recurrings" class="col mb-2">
                            <span class="font-weight-bold text-lg">#{{ recurring.id }} {{ recurring.campaign.name[lang] }}</span>
                            <span v-if="recurring.package" class="badge badge-secondary">{{ recurring.package.name[lang] }}</span><br>
                            <span v-if="recurring.bank" class="text-lg">{{ recurring.bank.name }}</span>
                            <span v-if="recurring.no_account" class="text-muted">({{ recurring.no_account }})</span>
                        </div>
                        <div v-if="!donor.active_recurrings.length">
                            <span class="text-secondary">No Subscriptions</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- form_recurring_card -->
            <div v-if="donorMode == 'new' || (donorMode == 'find' && this.donor.id)" class="card-body" id="form_recurring_card">
                <div class="row">
                    <div class="col-3">
                        <h4>Subscription Details</h4>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">Method <span class="text-muted">(Optional)</span></div>
                                    <select class="form-control custom-select placeholder" name="method">
                                        <option :value="null">Please Pick One...</option>
                                        <option v-for="method in methods" :value="method.id">{{ method.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">Sector <span class="text-muted">(Optional)</span></div>
                                    <select class="form-control custom-select placeholder" name="sector">
                                        <option :value="null">Please Pick One...</option>
                                        <option v-for="sector in sectors" :value="sector.id">{{ sector.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">Medium <span class="text-muted">(Optional)</span></div>
                                    <select class="form-control custom-select placeholder" name="medium">
                                        <option :value="null">Please Pick One...</option>
                                        <option v-for="medium in mediums" :value="medium.id">{{ medium.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="form-label">Frequency <span class="text-muted">(Optional)</span></div>
                                    <select class="form-control custom-select placeholder" name="frequency">
                                        <option :value="null">Please Pick One...</option>
                                        <option v-for="frequency in frequencies" :value="frequency.id">{{ frequency.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-label">Bank 
                                <span v-if="campaignId == autodebitId" class="text-red">*</span>
                                <span v-else class="text-muted">(optional)</span>
                            </div>
                            <select class="form-control custom-select placeholder" name="bank" style="width: 75%;">
                                <option :value="null">Please Pick One...</option>
                                <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div class="form-label">
                                No. Bank Account 
                                <span v-if="campaignId == autodebitId" class="text-red">*</span>
                                <span v-else class="text-muted">(optional)</span>
                            </div>
                            <input class="form-control" name="no_account" style="width: 50%;" type="text">
                        </div>
                        <div class="form-group">
                            <label class="form-label">Campaign <span class="text-red">*</span></label>
                            <div class="selectgroup w-50" :onchange="getAmount()">
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="campaign_id" :value="autodebitId" v-model="campaignId" checked>
                                    <span class="selectgroup-button">Autodebit</span>
                                </label>
                                <label class="selectgroup-item">
                                    <input type="radio" class="selectgroup-input" name="campaign_id" :value="kafalahId" v-model="campaignId">
                                    <span class="selectgroup-button">Kafalah (Penajaan)</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group" v-if="campaignId == kafalahId">
                            <div class="form-label">Package <span class="text-red">*</span></div>
                            <select class="form-control custom-select placeholder" name="package_id" style="width: 75%;" v-model="packageId" :onchange="getAmount()">
                                <option value="null">Please Pick One...</option>
                                <option v-for="campaignPackage in campaignPackages.filter(p => p.is_open == 0)" :value="campaignPackage.id">{{ campaignPackage.name[lang] }} <span v-if="campaignPackage.is_open == 0 && campaignPackage.amount">(RM{{ campaignPackage.amount }})</span> <span v-if="campaignPackage.archived_at">(Archived)</span></option>
                            </select>
                        </div>
                        <div class="form-group" v-if="campaignId == kafalahId">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-label">Start Date <span class="text-red">*</span></div>
                                    <input type="date" class="form-control" id="start_date" name="start_date" v-model="startDate" @change="setEndDate" required>
                                </div>
                                <div class="col-6">
                                    <div class="form-label">End Date <span class="text-red">*</span></div>
                                    <input type="date" class="form-control" id="end_date" name="end_date" v-model="endDate" required readonly>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-label" v-if="campaignId == autodebitId">Monthly Commitment (RM) <span class="text-red">*</span></div>
                            <div class="form-label" v-else>Donation Amount (RM) <span class="text-red">*</span></div>
                            <money class="form-control w-50" id="amount" v-model="amount" v-bind="money" :readonly="campaignId == kafalahId"></money>
                            <input type="hidden" name="amount" v-model="amount">
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-9 offset-3 mt-6">
                        <button type="submit" class="btn btn-primary">Add Subscription</button>
                    </div>
                </div>
            </div>

        </div> <!-- end card -->

    </div>
</template>
<script>
    import {Money} from 'v-money'
    export default {
        components: {Money},
        data() {
            return {
                donor: this.initDonor ? JSON.parse(this.initDonor) : { id: '' },
                donorMode: 'new',
                campaignId: this.initCampaignId ? this.initCampaignId : this.autodebitId,
                packageId: null,
                amount: 0,
                selectedGender: null,
                selectedBranch: this.authUser.branch_id,
                startDate: null,
                endDate: null,
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: 'RM ',
                    suffix: '',
                    precision: 2,
                    masked: false
                }
            };
        },
        props: [
            'lang',
            'initDonor',
            'initCampaignId',
            'kafalahId',
            'autodebitId',
            'presalutations',
            'salutations',
            'genders',
            'maritals',
            'states',
            'countries',
            'banks',
            'branches',
            'campaignPackages',
            'authUser',
            'malaysiaId',
            'methods',
            'sectors',
            'mediums',
            'frequencies',
        ],
        computed: {
        },
        methods: {
            getAmount() {
                if (this.campaignId == this.autodebitId) {
                    this.amount = this.amount;
                } else {
                    let amount = this.campaignPackages.find(thePackage => this.packageId == thePackage.id) ? this.campaignPackages.find(thePackage => this.packageId == thePackage.id).amount : 0
                    
                    this.amount = amount;
                }
            },
            handleChangeDonor(donor) {
                this.donor = donor;
            },
            setEndDate() {
                this.endDate = moment(this.startDate).add(1, 'years').subtract(1, 'days').format("YYYY-MM-DD");
            }
        }, 
        mounted() {
        }
    }
</script>