<template>
<div>
    <div class="d-flex align-items-center justify-content-between mt-4">
        <a :href="routeBack" class="btn btn-secondary">
            <i class="fe fe-chevron-left mr-2"></i>
            {{ trans['Kembali'] }}
        </a>
    </div>
    <div class="card mt-4">
        <div class="card-body">
            <div class="row">
                <div class="col-2">
                    <h4>Campaign Details</h4>
                    <p class="text-muted">Please fill in form for both languages Malay (MS) and English (EN) in order to add new campaign</p>
                </div>
                <!-- MS -->
                <div class="col-5">
                    <div id="campaign-language-button-ms" :class="`d-flex justify-content-start position-relative mb-3 border-0 campaign-language-button ${getContentValidity('ms') ? 'content-valid' : ''}`">MS</div>
                    <div :class="`form-group ${initCampaign ? 'mb-1' : ''}`">
                        <div class="form-label">Name <span class="text-red">*</span></div>
                        <input type="text" class="form-control" name="name[ms]" id="name_ms" placeholder="Kempen Korban Palestin" v-model="campaign.name['ms']" required>
                        <span v-if="errors['name.ms'] && errors['name.ms'][0]" class="text-danger text-sm">{{ errors['name.ms'][0] }}</span>
                    </div>

                    <span v-if="initCampaign" class="text-muted">URL: <a target="_blank" class="btn btn-link pl-0 pb-2" :href="`/ms/kempen/${campaign.slug.ms}`">{{ `https://jommenyumbang.com/ms/kempen/${campaign.slug['ms']}` }} <i class="fa fa-external-link"></i></a></span>

                    <div class="form-group">
                        <div class="form-label">Description <span class="text-red">*</span></div>
                        <editor
                            api-key="ld33zdqw1j584sq2xzacd6z5uk91buqq8fgcbo99fscraepd"
                            name="description[ms]"
                            id="description_ms"
                            :init="{
                                height: 300,
                                plugins: [
                                'emoticons',
                                'link'
                                ],
                            }"
                            v-model="campaign.description['ms']"
                            required="true"
                        />
                        <span v-if="errors['description.ms'] && errors['description.ms'][0]" class="text-danger text-sm">{{ errors['description.ms'][0] }}</span>
                    </div>

                    <div class="form-group">
                        <div class="form-label">Campaign Poster 
                            <span class="text-red">*</span>
                            <span class="text-secondary">(Prefered Image Size: 960x512)</span>
                        </div>
                        <div class="d-block align-items-center mb-2">
                            <div class="custom-file" style="width:70%">
                                <input id="image_ms" class="custom-file-input" type="file" name="image_ms" ref="image_ms" @change="handleFileUpload('ms')">
                                <label class="custom-file-label" for="image_ms">Choose file</label>
                            </div>
                            <p class="mb-0 ml-2">{{ images['ms'] ? images['ms'].name : '' }}</p>
                        </div>
                        <div v-if="mode == 'edit' && getCampaignImageUrl()">
                            <img :src="getCampaignImageUrl()" class="img-thumbnail mb-2" style="height: 150px; width: auto;">
                        </div>
                        <div v-if="campaign.imageUrls.images && campaign.imageUrls['images'] && !campaign.imageUrls['images-ms']">
                            <img :src="campaign.imageUrls.images" class="img-thumbnail mb-2" style="height: 150px; width: auto;">
                            <p class="text-danger">
                                Default poster is already available. Translation poster is required
                            </p>
                        </div>
                    </div>
                </div>
                <!-- EN -->
                <div class="col-5">
                    <div id="campaign-language-button-en" :class="`d-flex justify-content-start position-relative mb-3 border-0 campaign-language-button ${getContentValidity('en') ? 'content-valid' : ''}`">EN</div>
                        
                    <div :class="`form-group ${initCampaign ? 'mb-1' : ''}`">
                        <div class="form-label">Name <span class="text-red">*</span></div>
                        <input type="text" class="form-control" name="name[en]" id="name_en" placeholder="Kempen Korban Palestin" v-model="campaign.name['en']" required>
                        <span v-if="errors['name.en'] && errors['name.en'][0]" class="text-danger text-sm">{{ errors['name.en'][0] }}</span>
                    </div>

                    <span v-if="initCampaign" class="text-muted">URL: <a target="_blank" class="btn btn-link pl-0 pb-2" :href="`/en/kempen/${campaign.slug.en}`">{{ `https://jommenyumbang.com/en/kempen/${campaign.slug['en']}` }} <i class="fa fa-external-link"></i></a></span>

                    <div class="form-group">
                        <div class="form-label">Description <span class="text-red">*</span></div>
                        <editor
                            api-key="ld33zdqw1j584sq2xzacd6z5uk91buqq8fgcbo99fscraepd"
                            name="description[en]"
                            id="description_en"
                            :init="{
                                height: 300,
                                plugins: [
                                'emoticons',
                                'link'
                                ],
                            }"
                            v-model="campaign.description['en']"
                            required="true"
                        />
                        <span v-if="errors['description.en'] && errors['description.en'][0]" class="text-danger text-sm">{{ errors['description.en'][0] }}</span>
                    </div>

                    <div class="form-group">
                        <div class="form-label">Campaign Poster 
                            <span class="text-red">*</span>
                            <span class="text-secondary">(Prefered Image Size: 960x512)</span>
                        </div>
                        <div class="d-block align-item-center mb-2">
                            <div class="custom-file" style="width:70%">
                                <input id="image_en" class="custom-file-input" type="file" name="image_en" ref="image_en" @change="handleFileUpload('en')">
                                <label class="custom-file-label" for="image_en">Choose file</label>
                            </div>
                            <p class="mb-0 ml-2">{{ images['en'] ? images['en'].name : '' }}</p>
                        </div>
                        <div v-if="mode == 'edit' && getCampaignImageUrl()">
                            <img :src="getCampaignImageUrl()" class="img-thumbnail mb-2" style="height: 150px; width: auto;">
                        </div>
                        <div v-if="campaign.imageUrls.images && campaign.imageUrls['images'] && !campaign.imageUrls['images-en']">
                            <img :src="campaign.imageUrls.images" class="img-thumbnail mb-2" style="height: 150px; width: auto;">
                            <p class="text-danger">
                                Default poster is already available. Translation poster is required
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-2">
                    <h4>Package Details</h4>
                </div>
                <div class="col-10">
                    <package-form
                        :init-packages="initCampaign ? initCampaign.packages : campaign.packages"
                        @packagesContentChange="handlePackagesContentChange($event)"
                        :errors="errors"
                    >
                    </package-form>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-10">
                    <div class="form-group col-3 pl-0" v-if="currentRoleUser == 'admin'">
                        <div class="form-label">Publish Status</div>
                        <select class="custom-select" name="publish_status" id="publish_status" v-model="publishStatus">
                            <option value="" selected>Select one...</option>
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                        </select>
                    </div>
                    <div class="form-group col-4 pl-0" v-else>
                        <input class="form-control" type="hidden" name="publish_status" id="publish_status" v-model="publishStatus">
                    </div>

                    <div class="row">
                        <div class=" col-2 form-group">
                            <div class="form-label">Start Date</div>
                            <input class="form-control" type="date" name="start_at" v-model="campaign.start_at"></input>
                        </div>

                        <div class="col-2 form-group">
                            <div class="form-label">End Date</div>
                            <input class="form-control" type="date" name="end_at" v-model="campaign.end_at"></input>
                        </div>
                    </div>

                    <div class="form-group col-4 pl-0">
                        <div class="form-label">Amount Goal (RM) <span class="text-red">*</span></div>
                        <input type="number" class="form-control" name="amount_targeted" id="amount_targeted" required v-model="campaign.amount_targeted">
                    </div>

                    <div class="form-group col-4 pl-0">
                        <div class="form-label">Billplz Collection ID <span class="text-red">*</span></div>
                        <input type="text" class="form-control" name="collection_id" id="collection_id" required v-model="campaign.collection_id">
                    </div>

                    <div class="form-group">
                        <div class="form-label">Settings</div>
                        <div class="custom-controls-stacked">
                            <label class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" name="is_name_required" v-model="campaign.isNameRequired">
                                <div class="custom-control-label">This campaign requires Donors' Name (Korban)</div>
                            </label>
                            <label class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" name="is_anonymous_disallowed" value="is_anonymous_disallowed" :checked="campaign.isAnonymousDisallowed">
                                <div class="custom-control-label">This campaign requires Donors' Details (Anonymous are not allowed)</div>
                            </label>
                        </div>

                    </div>

                    <div class="d-flex justify-content-start">
                        <button v-if="mode == 'add'" type="button" id="add-campaign" @click="submit()" class="btn btn-primary" :disabled="!getFormValidity() || isUpdating">Add Campaign</button>
                        <button v-else type="button" id="update-campaign" @click="submit()" class="btn btn-primary" :disabled="!getFormValidity() || isUpdating">Update Campaign</button>
                        <moon-loader class="ml-2" :loading="isUpdating" id="spinner" :color="'#26A65B'" :size="'36px'"></moon-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
    .campaign-language-button.content-valid::after {
        background-color: #1d9f3c;
    }
    .campaign-language-button:not(.content-valid) {
        color: #cd201f !important;
    }
    .campaign-language-button {
        padding-left: 1rem !important;
    }
    .campaign-language-button::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        height: 7px;
        width: 7px;
        background-color: #cd201f;
        left: 1px;
        top: 55%;
        margin-top: -4px;
    }
    .campaign-toast {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
        color: white;
        background-color: #5eba00 !important;
    }
</style>

<script>
    import { MoonLoader } from 'vue-spinner/dist/vue-spinner.min';
    import Editor from '@tinymce/tinymce-vue';

    export default {
        data() {
            return {
                lang: this.initLang,
                campaign: {
                    name: {
                        'en': '',
                        'ms': ''
                    },
                    slug: {
                        'en': '',
                        'ms': ''
                    },
                    description: {
                        'en': '',
                        'ms': ''
                    },
                    imageUrls: [],
                    start_at: '',
                    end_at: '',
                    amount_targeted: '',
                    collection_id: '',
                    packages: [
                        {
                            name: {
                                'en': '',
                                'ms': ''
                            },
                            amount: '',
                            archived_at: '',
                            is_open: 0
                        }
                    ]
                },
                publishStatus: this.initCampaign && this.initCampaign.published_at ? 'published' : 'draft',
                images: {
                    ms: '',
                    en: ''
                },
                errors: {},
                isUpdating: false
            };
        },
        components: {
            MoonLoader,
            'editor': Editor
        },
        props: {
            routeBack: {},
            route: {},
            csrf: {},
            trans: {},
            initLang: {},
            mode: {},
            initCampaign: {
                default: function() {
                    return null;
                },
            },
            currentRoleUser: {},
        },
        computed: {
        },
        methods: {
            handleFileUpload(lang) {
                this.images[lang] = this.$refs[`image_${lang}`].files[0];
            },
            submit() {
                this.isUpdating = true;
                let data = new FormData();

                if (this.images['ms']) {
                    data.append('image_ms', this.images['ms']);
                }

                if (this.images['en']) {
                    data.append('image_en', this.images['en']);
                }

                if (this.mode == 'edit') {
                    data.append('_method', 'PUT');
                }
                
                data.append('publish_status', this.publishStatus);

                Object.keys(this.campaign).forEach(key => {
                    let input = 
                        ['name', 'slug', 'description', 'preferences', 'packages', 'media'].includes(key)
                            ? JSON.stringify(this.campaign[key])
                            : this.campaign[key];

                    data.append(key, input);

                    if (key == 'start_at' && this.campaign.start_at == '')
                        data.append(key, null);

                    if (key == 'end_at' && this.campaign.end_at == '')
                        data.append(key, null);
                });

                // for (var pair of data.entries()) {
                //     console.log(pair[0]+ ', ' + pair[1]); 
                // }

                axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                axios.post(
                    this.route,
                    data
                ).then((response) => {
                    this.$toasted.show(`Campaign ${this.mode == 'add' ? 'added' : 'updated'}`, { 
                        theme: "toasted-primary", 
                        position: "bottom-left", 
                        duration : 20000,
                        icon: {name: 'check'},
                        className: ['campaign-toast', 'font-weight-bold']
                    });

                    this.resetData(response.data);

                    if (this.mode == 'add') {
                        window.location.href = this.routeBack;
                    } else {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }
                    this.isUpdating = false;
                }).catch((error) => {
                    // console.log(error);
                    window.scrollTo(0,0);
                    this.errors = _.get(error, 'response.data.errors');
                    this.isUpdating = false;
                });
            },
            handlePackagesContentChange(data) {
                Vue.set(this.campaign, 'packages', data.packages);
            },
            getCampaignImageUrl() {
                return this.campaign.imageUrls[`images-${this.lang}`];
            },
            resetData(campaign) {
                campaign.start_at = campaign.start_at != 'null' ? moment(campaign.start_at).format('YYYY-MM-DD') : null;
                campaign.end_at = campaign.end_at != 'null' ? moment(campaign.end_at).format('YYYY-MM-DD') : null;
                this.campaign = campaign;
                this.publishStatus = campaign.published_at ? 'published' : 'draft';
                this.images = {
                    ms: '',
                    en: ''
                };
                this.errors = {};
            },
            getContentValidity(lang) {
                let invalidPackageExist = this.campaign.packages.length && _.find(this.campaign.packages, p => {
                    return !p.name[lang] || ((!p.amount || p.amount < 3) && !p.is_open);
                }) !== undefined;

                let valid = this.campaign.name[lang] &&
                        this.campaign.description[lang] &&
                        (this.campaign.imageUrls[`images-${lang}`] || this.images[lang]);

                return valid;
            },
            getFormValidity() {
                return this.getContentValidity('ms') && this.getContentValidity('en') && this.campaign.amount_targeted && this.campaign.collection_id;
            }
        },
        mounted() {
            if (this.initCampaign) {
                this.campaign = this.initCampaign;
                if (this.initCampaign.start_at) {
                    this.campaign.start_at = moment(this.initCampaign.start_at).format('YYYY-MM-DD');
                }
                if (this.initCampaign.end_at) {
                    this.campaign.end_at = moment(this.initCampaign.end_at).format('YYYY-MM-DD');
                }
            }
        }
    }
</script>