<template>
    <div class="form-group">
        <div class="form-label">{{ label }} <span v-if="required" style="color:red;font-weight:bold">*</span></div>
        <select class="form-control custom-select placeholder" :name="name" v-model="currentItemId" :required="required">
            <option>Please Pick One...</option>
            <option v-for="item in items" :value="item.id">{{ item.name }}</option>
        </select>
    </div>    
</template>

<script>
    export default {
        data() {
            return {
                currentItemId: this.defaultItemId
            };
        },
        props: {
            label: {
                default: '',
            },
            name: {
                default: '',
            },
            items: {
                default: function () {
                    return [];
                }
            },
            defaultItemId: {
                default: ''
            },
            required: {
                default: false
            }
        },
        computed: {
        },
        methods: {
        },
        mounted() {
        }
    }
</script>